import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiIcon,
  EuiLoadingSpinner,
  EuiSelectable,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTitle,
  EuiComboBox,
  euiPaletteColorBlindBehindText,
  EuiHorizontalRule,
  EuiButton,
  EuiConfirmModal,
  EuiLink,
  EuiCallOut,
  EuiFlyoutFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiCode,
  EuiCodeBlock,
  EuiButtonGroup,
  EuiBasicTable,
  EuiInMemoryTable,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  formatDate,
  EuiCopy,
  EuiButtonIcon,
  EuiLoadingChart,
} from "@elastic/eui";
import React, { Fragment, useEffect, useState } from "react";
import LoginService from "../services/login-service";
import StatsService from "../services/stats-service";

const visColorsBehindText = euiPaletteColorBlindBehindText();

const DashboardOutFlyout = (props) => {
  const [NEWoptions, setNEWOptions] = useState();
  const [NEWselectedOptions, setNEWSelected] = useState();
  const [NEWagentsLoading, setNEWAgentsLoading] = useState(false);
  const [NEWname, setNEWname] = useState();
  const [selectedTabId, setSelectedTabId] = useState("1");
  const [isSelectableLoading, setIsSelectableLoading] = useState(true);
  const [isTagSelected, setIsTagSelected] = useState(false);
  const [isTagSelectedLoading, setIsTagSelectedLoading] = useState(false);
  const [tags, setTags] = useState();

  const [nameTagSelected, setNameTagSelected] = useState();
  const [dataTagSelected, setDataTagSelected] = useState();
  const [tagsPlain, setTagsPlain] = useState();

  const clickedOnItemToCopy = (item) => {
    navigator.clipboard.writeText(item.url);
  };

  const [isLoadingCreatorLoading, setisLoadingCreatorLoading] = useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const columns = [
    {
      name: "Creator",
      truncateText: true,
      render: (item) => (
        <EuiText size="s">
          {isLoadingCreatorLoading ? (
            <>
              <EuiText size="s">
                <EuiLoadingChart size="m" mono />
                &nbsp;&nbsp;Loading...
              </EuiText>
            </>
          ) : (
            <>{item.creator} </>
          )}
        </EuiText>
      ),
    },
    {
      field: "dateRange",
      name: "Content",
      truncateText: true,
    },
    {
      field: "expiration",
      name: "Expiration Date",
      dataType: "date",
      render: (date) => (
        <>
          {formatDate(date)}

          {Math.round((new Date(date) - today) / (1000 * 60 * 60 * 24)) <= 1 ? (
            <>
              &nbsp;
              <EuiButtonIcon
                title={"Caution! Token Expired"}
                aria-label={"warning"}
                iconType={"cross"}
              />
            </>
          ) : (
            <>
              {Math.round((new Date(date) - today) / (1000 * 60 * 60 * 24)) <=
              2 ? (
                <>
                  &nbsp;
                  <EuiButtonIcon
                    title={"Caution! Your token will expire within 1 day."}
                    aria-label={"warning"}
                    iconType={"alert"}
                  />
                </>
              ) : (
                <>
                  {Math.round(
                    (new Date(date) - today) / (1000 * 60 * 60 * 24)
                  ) <= 8 ? (
                    <>
                      &nbsp;
                      <EuiButtonIcon
                        title={"Caution! Your token will expire within 7 days."}
                        aria-label={"warning"}
                        iconType={"alert"}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          )}
        </>
      ),
      sortable: true,
      truncateText: true,
    },
    {
      field: "url",
      name: "URL",
      truncateText: true,
    },
    {
      name: "Actions",
      actions: [
        {
          name: "Clone",
          description: "Copy URL",
          icon: "copy",
          type: "icon",
          render: (item) => (
            <EuiCopy textToCopy={item.url}>
              {(copy) => (
                <EuiButtonIcon iconType={"copy"} onClick={copy}></EuiButtonIcon>
              )}
            </EuiCopy>
          ),
        },
        {
          name: "Delete",
          description: "Delete URL",
          icon: "trash",
          type: "icon",
          color: "danger",
          onClick: (item) => {
            setUrlToDelete(item.id);
            SetIsModalConfirmDeleteUrlVisible(true);
          },
        },
      ],
    },
  ];

  const [urlToDelete, setUrlToDelete] = useState();

  const confirmUrlDelete = (item) => {
    const token = sessionStorage.getItem("krakenT");

    StatsService.deleteUrl(token, urlToDelete)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    closeModalDeletePublicUrl();
    showModalManagePublicUrl();
  };

  const granularityToggleButtons = [
    {
      id: 1,
      label: "Last 24h",
    },
    {
      id: 2,
      label: "Current Week",
    },
    {
      id: 3,
      label: "Current Month",
    },
  ];

  const lifeTimeButtons = [
    {
      id: 1,
      label: "24h",
    },
    {
      id: 2,
      label: "7d",
    },
    {
      id: 3,
      label: "30d",
    },
    {
      id: 4,
      label: "90d",
    },
    {
      id: 5,
      label: "1y",
    },
  ];

  const [
    granularityToggleButtonsIdSelected,
    setGranularityToggleButtonsIdSelected,
  ] = useState(1);

  const [lifeTimeButtonsSelected, setLifeTimeButtonsSelected] = useState(1);

  const onGranularityChange = (optionId) => {
    setGranularityToggleButtonsIdSelected(optionId);
  };

  const onLifeTimeChange = (optionId) => {
    setLifeTimeButtonsSelected(optionId);
  };
  const tabs = [
    {
      id: "1",
      name: "Active tags",
    },
    {
      id: "2",
      name: (
        <>
          <EuiIcon type="plusInCircleFilled" />
          &nbsp; Create new tag
        </>
      ),
    },
  ];

  const [isDeleteResponseErrorred, setIsFDeleteResponseErrored] =
    useState(false);
  const [isDeleteResponseSuccess, setIsFDeleteResponseSuccess] =
    useState(false);
  const [isNEWURLErrorred, setIsNEWURLErrorred] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalGeneratePublicUrlVisible, setIsModalGeneratePublicUrlVisible] =
    useState(false);
  const [isModalManagePublicUrlVisible, setIsModalManagePublicUrlVisible] =
    useState(false);

  const [urlList, setUrlList] = useState([
    { creator: "", content: "", expiration: "", url: "" },
  ]);
  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  const closeModalPublicUrl = () => setIsModalGeneratePublicUrlVisible(false);
  const showModalPublicUrl = () => setIsModalGeneratePublicUrlVisible(true);

  const closeModalDeletePublicUrl = () =>
    SetIsModalConfirmDeleteUrlVisible(false);

  const showModalDeletePublicUrl = () =>
    SetIsModalConfirmDeleteUrlVisible(true);

  const closeModalManagePublicUrl = () =>
    setIsModalManagePublicUrlVisible(false);

  const showModalManagePublicUrl = () => {
    setisLoadingCreatorLoading(true);
    setUrlList([]);
    const token = sessionStorage.getItem("krakenT");
    const tag = nameTagSelected;
    StatsService.getUrls(token, tag)
      .then((response) => {
        setUrlList(response.data.message);
        setisLoadingCreatorLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setisLoadingCreatorLoading(false);
      });
    setIsModalManagePublicUrlVisible(true);
  };

  const [agentsLoading, setAgentsLoading] = useState(false);

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const renderTabs = tabs.map((tab, index) => (
    <EuiTab
      onClick={() => setSelectedTabId(tab.id)}
      isSelected={tab.id === selectedTabId}
      key={index}
    >
      {tab.name}
    </EuiTab>
  ));

  const fncTagSelected = (newOptions) => {
    setSaveErrorVisible(false);
    setSaveSuccessVisible(false);
    setAgentsLoading(true);
    setIsTagSelected(true);
    setIsTagSelectedLoading(true);
    const tagToFound = newOptions.find((e) => e.checked === "on");
    const found = tagsPlain.find((e) => e.id === tagToFound.id);
    setDataTagSelected(found);
    setNameTagSelected(found.name);
    setIsTagSelectedLoading(false);

    const ags = [];

    for (let agent of found.agents) {
      ags.push({ label: agent });
    }
    setSelected(ags);

    var oldArray = options;

    for (let agentToDelete of found.agents) {
      const index = oldArray.indexOf(agentToDelete.label);
      if (index > -1) {
        // only splice array when item is found
        oldArray.splice(index, 1); // 2nd parameter means remove one item only
      }
    }

    setOptions(oldArray);
    setAgentsLoading(false);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("krakenT");
    setNEWAgentsLoading(true);
    // Get Tags
    StatsService.getTags(token)
      .then((response) => {
        const tags = [];
        for (let tag of response.data.message) {
          tags.push({ label: tag.name, id: tag.id, agents: tag.agents });
        }
        setTags(tags);
        setTagsPlain(response.data.message);
        setIsSelectableLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });

    // Get Agents
    StatsService.getAgents(token)
      .then((response) => {
        const agents = [];
        for (let agent of response.data.message) {
          agents.push({
            label: agent,
          });
        }
        setOptions(agents);
        setNEWOptions(agents);
        setNEWAgentsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [options, setOptions] = useState();
  const [selectedOptions, setSelected] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (selectedOptions) => {
    setSelected(selectedOptions);
    setSaveErrorVisible(false);
    setSaveSuccessVisible(false);
    setSaveDisabled(false);
  };

  const onNewChange = (selectedOptions) => {
    setNEWSelected(selectedOptions);
    setSaveErrorVisible(false);
    setSaveSuccessVisible(false);
    setSaveDisabled(false);
  };

  const onCreateOption = (searchValue, flattenedOptions = []) => {
    if (!searchValue) {
      return;
    }

    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      label: searchValue,
    };

    // Create the option if it doesn't exist.
    if (
      flattenedOptions.findIndex(
        (option) => option.label.trim().toLowerCase() === normalizedSearchValue
      ) === -1
    ) {
      setOptions([...options, newOption]);
    }

    // Select the option.
    setSelected((prevSelected) => [...prevSelected, newOption]);
  };

  const [saveErrorVisible, setSaveErrorVisible] = useState(false);
  const [saveSuccessVisible, setSaveSuccessVisible] = useState(false);
  const [isNEWSuccessVisible, setIsNEWSuccessVisible] = useState(false);
  const [isNEWErroredVisible, setisNEWErroredVisible] = useState(false);

  const [saveNewUrlLoading, setSaveNewUrlLoading] = useState(false);
  const [saveNewUrlErrored, setSaveNewUrlErrored] = useState(false);

  const [newUrlConfirmedMessage, setNewUrlConfirmedMessage] = useState("");
  const [isNEWURLSuccessVisible, SetIsNEWURLSuccessVisible] = useState(false);

  const [isModalConfirmDeleteUrlVisible, SetIsModalConfirmDeleteUrlVisible] =
    useState(false);
  const [newPublicUrl, setNewPublicUrl] = useState("");

  const confirmedGenerateUrl = async () => {
    setSaveNewUrlLoading(true);
    SetIsNEWURLSuccessVisible(false);
    setIsNEWURLErrorred(false);

    const token = sessionStorage.getItem("krakenT");
    const body = {
      tag: nameTagSelected,
      dateRange:
        granularityToggleButtons[granularityToggleButtonsIdSelected - 1].label,
      expires_in: lifeTimeButtons[lifeTimeButtonsSelected - 1].label,
    };
    LoginService.create_public_token(token, body)
      .then((response) => {
        SetIsNEWURLSuccessVisible(true);
        setSaveNewUrlLoading(false);
        setNewPublicUrl(
          `https://st2.kunzite.app/shared/outbound/?token=${response.data.accessToken}`
        );
      })
      .catch((error) => {
        console.log(error);
        setIsNEWURLErrorred(true);
        setSaveNewUrlLoading(false);
      });
  };

  const clickedOnConfirm = async () => {
    setIsFDeleteResponseErrored(false);
    const token = sessionStorage.getItem("krakenT");
    const tagId = dataTagSelected.id;

    setIsLoading(true);
    StatsService.deleteTag(token, tagId)
      .then(async (response) => {
        if (response.data.message !== null) {
          setIsFDeleteResponseErrored(false);
          setIsFDeleteResponseSuccess(true);
          setIsLoading(false);
          setIsModalVisible(false);
          setIsSelectableLoading(true);
          setIsTagSelected(false);
          StatsService.getTags(token)
            .then((response) => {
              const tags = [];
              for (let tag of response.data.message) {
                tags.push({ label: tag.name, id: tag.id });
              }
              setIsFDeleteResponseSuccess(false);
              setIsSelectableLoading(false);
              setTags(tags);
              setTagsPlain(response.data.message);
            })

            .catch(() => {
              setIsFDeleteResponseSuccess(false);
              setIsFDeleteResponseErrored(true);
              setIsLoading(false);
            });
        } else {
          setIsFDeleteResponseSuccess(false);
          setIsFDeleteResponseErrored(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsFDeleteResponseErrored(true);
        setIsLoading(false);
      });
  };

  const saveChanges = () => {
    setSaveLoading(true);
    const token = sessionStorage.getItem("krakenT");
    const tagId = dataTagSelected.id;
    const agents = [];
    for (let agent of selectedOptions) {
      agents.push(agent.label);
    }
    StatsService.updateTag(token, tagId, agents)
      .then((response) => {
        setSaveSuccessVisible(true);
        setSaveLoading(false);
        setIsSelectableLoading(true);
        setIsTagSelected(false);
        StatsService.getTags(token)
          .then((response) => {
            const tags = [];
            for (let tag of response.data.message) {
              tags.push({ label: tag.name, id: tag.id });
            }
            setIsFDeleteResponseSuccess(false);
            setIsSelectableLoading(false);
            setTags(tags);
            setTagsPlain(response.data.message);
          })

          .catch(() => {
            setIsFDeleteResponseSuccess(false);
            setIsFDeleteResponseErrored(true);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setSaveErrorVisible(true);
        setSaveLoading(false);
      });
  };

  const [isNEWSaveLoading, setNEWSaveLoading] = useState(false);

  const clickedOnSaveNewTag = () => {
    setNEWSaveLoading(true);
    const token = sessionStorage.getItem("krakenT");
    const name = NEWname;
    const agents = [];

    for (let agent of NEWselectedOptions) {
      agents.push(agent.label);
    }
    StatsService.newTag(token, name, agents)
      .then((response) => {
        setIsNEWSuccessVisible(true);
        setIsModalVisible(false);
        setIsSelectableLoading(true);
        setIsTagSelected(false);
        StatsService.getTags(token)
          .then((response) => {
            const tags = [];
            for (let tag of response.data.message) {
              tags.push({ label: tag.name, id: tag.id });
            }
            setIsFDeleteResponseSuccess(false);
            setIsSelectableLoading(false);
            setTags(tags);
            setTagsPlain(response.data.message);
            setSelectedTabId("1");
          })

          .catch(() => {
            setIsFDeleteResponseSuccess(false);
            setIsFDeleteResponseErrored(true);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setisNEWErroredVisible(true);
      });
  };

  return (
    <>
      {" "}
      <EuiFlyout
        ownFocus
        onClose={() => props.closeFlyout()}
        aria-labelledby={"simpleFlyoutTitleId"}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id={"Header"}>
              <EuiIcon size={"xl"} type="tag" />
              &nbsp; Manage tags
            </h2>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiSpacer size="s" />
          <EuiTabs style={{ marginBottom: "-25px" }}>{renderTabs}</EuiTabs>
        </EuiFlyoutHeader>
        {selectedTabId === "1" && (
          <>
            <EuiFlyoutBody>
              <EuiSelectable
                singleSelection
                isLoading={isSelectableLoading}
                aria-label="Searchable example"
                searchable
                searchProps={{
                  "data-test-subj": "selectableSearchHere",
                  placeholder: "Select one to edit",
                }}
                options={tags}
                onChange={(newOptions) => {
                  fncTagSelected(newOptions);
                }}
              >
                {(list, search) => (
                  <>
                    {search}
                    {list}
                  </>
                )}
              </EuiSelectable>
              {isTagSelected && (
                <>
                  <EuiSpacer size="l" />
                  {isTagSelectedLoading ? (
                    <EuiLoadingSpinner />
                  ) : (
                    <>
                      <div style={{ display: "flex" }}>
                        <div>
                          <EuiTitle>
                            <h1>{nameTagSelected}</h1>
                          </EuiTitle>
                        </div>
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "10px",
                          }}
                        >
                          <EuiButton
                            color="success"
                            iconType="plus"
                            style={{
                              height: 30,
                            }}
                            onClick={() => {
                              showModalPublicUrl();
                            }}
                          >
                            Add Public URL
                          </EuiButton>
                          {"  "}
                          <EuiButton
                            color="primary"
                            iconType="share"
                            style={{
                              height: 30,
                            }}
                            onClick={() => {
                              showModalManagePublicUrl();
                            }}
                          >
                            Manage Public URLs
                          </EuiButton>
                          {"  "}
                          <EuiButton
                            color="danger"
                            iconType="trash"
                            style={{
                              height: 30,
                            }}
                            onClick={() => {
                              showModal();
                            }}
                          >
                            Delete Tag
                          </EuiButton>
                        </div>
                      </div>
                      <EuiSpacer size="s" />
                      <EuiHorizontalRule
                        margin="none"
                        style={{ height: 2, marginRight: 10 }}
                      />
                      <EuiSpacer size="m" />
                      <EuiText size="s">Selected Agents</EuiText>
                      <EuiSpacer size="xs" />
                      <EuiComboBox
                        isLoading={agentsLoading}
                        aria-label="Accessible screen reader label"
                        placeholder="Select or create options"
                        fullWidth
                        options={options}
                        selectedOptions={selectedOptions}
                        onChange={onChange}
                        onCreateOption={onCreateOption}
                      />
                    </>
                  )}
                </>
              )}
              <EuiSpacer size="l" />
              <EuiFlexGroup>
                {saveSuccessVisible && (
                  <EuiCallOut size="s" color="success" iconType="user">
                    <p>Changes saved succesfully</p>
                  </EuiCallOut>
                )}
                {saveErrorVisible && (
                  <EuiCallOut size="s" color="danger" iconType="alert">
                    <p>
                      Unable to perform this action, please contact support -
                      soporte@astroline.com
                    </p>
                  </EuiCallOut>
                )}
              </EuiFlexGroup>
            </EuiFlyoutBody>
            <EuiFlyoutFooter>
              <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty
                    iconType="cross"
                    onClick={() => props.closeFlyout()}
                    flush="left"
                  >
                    Close
                  </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    isDisabled={saveDisabled}
                    onClick={() => saveChanges()}
                    isLoading={saveLoading}
                    fill
                  >
                    Save changes
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlyoutFooter>
          </>
        )}
        {selectedTabId === "2" && (
          <>
            {" "}
            <EuiFlyoutBody>
              <EuiForm component="form">
                <EuiFormRow
                  label="Name"
                  helpText="Type friendly name to the tag"
                >
                  <EuiFieldText
                    required
                    name="first"
                    onChange={(e) => setNEWname(e.target.value)}
                  />
                </EuiFormRow>
                <EuiSpacer size="l" />
                <EuiComboBox
                  isLoading={NEWagentsLoading}
                  placeholder="Select agents"
                  aria-label="Accessible"
                  fullWidth
                  options={NEWoptions}
                  selectedOptions={NEWselectedOptions}
                  onChange={onNewChange}
                  onCreateOption={onCreateOption}
                />{" "}
                <EuiSpacer size="l" />
                {isNEWErroredVisible && (
                  <>
                    <EuiSpacer size="s" />
                    <EuiCallOut size="s" color="danger" iconType="alert">
                      <p>
                        Unable to perform this action, please contact support -
                        soporte@astroline.com
                      </p>
                    </EuiCallOut>
                    <EuiSpacer size="s" />
                  </>
                )}
                {isNEWSuccessVisible && (
                  <>
                    <EuiSpacer size="s" />
                    <EuiCallOut size="s" color="success" iconType="user">
                      <p>Tag saved succesfully</p>
                    </EuiCallOut>
                    <EuiSpacer size="s" />
                  </>
                )}
                <EuiButton
                  onClick={() => {
                    clickedOnSaveNewTag();
                  }}
                >
                  Save
                </EuiButton>
              </EuiForm>
            </EuiFlyoutBody>
          </>
        )}
      </EuiFlyout>
      {isModalVisible && (
        <EuiConfirmModal
          title="Please confirm"
          onCancel={closeModal}
          onConfirm={() => {
            clickedOnConfirm();
          }}
          cancelButtonText="No, don't do it"
          confirmButtonText="Yes, do it"
          defaultFocusedButton="confirm"
          initialFocus="[name=delete]"
          isLoading={isLoading}
        >
          You&rsquo;re about to delete <b>{nameTagSelected}</b>
          <br></br>
          Are you sure you want to do this?
          {isDeleteResponseErrorred ? (
            <>
              <br></br>
              <br></br>
              <EuiCallOut
                size="s"
                title="Sorry, there was an error"
                color="danger"
                iconType="alert"
              >
                <p>
                  Unable to perform this action, please contact support -
                  soporte@astroline.com
                </p>
              </EuiCallOut>
              <br></br>
            </>
          ) : (
            ""
          )}
          {isDeleteResponseSuccess ? (
            <>
              <br></br>
              <br></br>
              <EuiCallOut
                size="s"
                title="Tag deleted successfully"
                color="success"
                iconType="user"
              >
                <p>Tag {nameTagSelected} deleted without errors.</p>
              </EuiCallOut>
              <br></br>
            </>
          ) : (
            ""
          )}
        </EuiConfirmModal>
      )}
      {isModalGeneratePublicUrlVisible && (
        <EuiConfirmModal
          title={`Generate new public URL for ${nameTagSelected}`}
          onCancel={closeModalPublicUrl}
          onConfirm={() => {
            confirmedGenerateUrl();
          }}
          cancelButtonText="Cancel"
          confirmButtonText="Create"
          defaultFocusedButton="confirm"
          initialFocus="[name=delete]"
          isLoading={saveNewUrlLoading}
        >
          In order to create a public URL please select the date range and the
          lifetime of the URL.
          <EuiSpacer></EuiSpacer>
          <EuiFormRow label="Date range" display="columnCompressed">
            <EuiButtonGroup
              legend="Granulariy of zoom levels"
              options={granularityToggleButtons}
              idSelected={granularityToggleButtonsIdSelected}
              onChange={onGranularityChange}
            />
          </EuiFormRow>
          <EuiSpacer></EuiSpacer>
          <EuiFormRow label="Lifetime" display="columnCompressed">
            <EuiButtonGroup
              legend="Granulariy"
              options={lifeTimeButtons}
              idSelected={lifeTimeButtonsSelected}
              onChange={onLifeTimeChange}
            />
          </EuiFormRow>
          {isNEWURLErrorred ? (
            <>
              <br></br>
              <br></br>
              <EuiCallOut
                size="s"
                title="Sorry, there was an error"
                color="danger"
                iconType="alert"
              >
                <p>
                  Unable to perform this action, please contact support -
                  soporte@astroline.com
                </p>
              </EuiCallOut>
              <br></br>
            </>
          ) : (
            ""
          )}
          {isNEWURLSuccessVisible && (
            <>
              <EuiSpacer size="s" />
              <EuiCallOut
                title="URL generated succesfully"
                color="success"
                iconType="faceHappy"
              >
                <EuiCodeBlock fontSize="m" paddingSize="m">
                  {newPublicUrl}
                </EuiCodeBlock>
              </EuiCallOut>

              <EuiSpacer size="s" />
            </>
          )}
        </EuiConfirmModal>
      )}
      {isModalManagePublicUrlVisible && (
        <>
          <EuiModal onClose={closeModalManagePublicUrl}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>{`Manage URLs for ${nameTagSelected}`}</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiSpacer></EuiSpacer>
            <div
              style={{
                marginLeft: "2.5%",
                marginRight: "2.5%",
              }}
            >
              <Fragment>
                <EuiInMemoryTable
                  tableCaption="Demo of EuiInMemoryTable "
                  items={urlList}
                  columns={columns}
                  pagination={false}
                  sorting={false}
                />
              </Fragment>
            </div>
            <EuiSpacer></EuiSpacer>
            <EuiButton
              style={{
                width: 100,
                marginRight: "5%",
                marginLeft: "auto",
                marginBottom: "2%",
              }}
              onClick={closeModalManagePublicUrl}
              primary
            >
              Close
            </EuiButton>
          </EuiModal>
        </>
      )}
      {isModalConfirmDeleteUrlVisible && (
        <>
          <EuiConfirmModal
            style={{ width: 600 }}
            title="Please confirm"
            onCancel={closeModalDeletePublicUrl}
            onConfirm={confirmUrlDelete}
            cancelButtonText="Cancel"
            confirmButtonText="Confirm"
            defaultFocusedButton="confirm"
          >
            <p>Are you sure you want to delete the URL?</p>
          </EuiConfirmModal>
        </>
      )}
    </>
  );
};

export default DashboardOutFlyout;
