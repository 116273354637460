import "@elastic/eui/dist/eui_theme_light.css";
import React, { useState, Fragment, useEffect } from "react";
import { Cdr } from "../components/Cdr";
import Dashboard from "../components/Dashboard";
import DashboardOUT from "../components/DashboardOUT";
import Realtime from "../components/Realtime";

const RenderPage = (props) => {
  const [selectedPage, setSelectedPage] = useState("light");

  useEffect(() => {
    setSelectedPage(props.page);
  }, [props]);
  return (
    <>
      {selectedPage === "Dashboard IN" ? <Dashboard /> : ""}
      {selectedPage === "CDR" ? <Cdr /> : ""}
      {selectedPage === "Dashboard OUT" ? <DashboardOUT /> : ""}
      {selectedPage === "Realtime" ? <Realtime /> : ""}
    </>
  );
};

export default RenderPage;
