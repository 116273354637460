import React, { useState } from "react";
import {
  EuiFieldSearch,
  EuiPopover,
  EuiButton,
  EuiContextMenu,
} from "@elastic/eui";
import BauhausService from "../services/bauhaus-service";

const BauhausAdhoc = ({
  surveyFilter,
  onSurveyFilterChange,
  startDate,
  endDate,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const calls_by_events = () => {
    const token = sessionStorage.getItem("krakenT");

    BauhausService.calls_by_events({
      token,
      initDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    })
      .then((response) => {
        console.log(response.data);
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Bauhaus_Calls_By_Events.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading CSV", error);
      });
  };

  const agent_sessions_to_csv = () => {
    const token = sessionStorage.getItem("krakenT");

    BauhausService.agent_sessions({
      token,
      initDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    })
      .then((response) => {
        console.log(response.data);
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Bauhaus_Agent_Sessions.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading CSV", error);
      });
  };

  const onButtonClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const items = [
    {
      name: "Agent Sessions",
      icon: "download",
      onClick: () => {
        agent_sessions_to_csv();
        closePopover();
      },
    },
    {
      name: "Calls By Events",
      icon: "download",
      onClick: () => {
        calls_by_events();
        closePopover();
      },
    },
    // Puedes añadir más items aquí si es necesario
  ];

  return (
    <div style={{ display: "flex", gap: 10 }}>
      <EuiFieldSearch
        title="Survey"
        style={{
          width: 500,
        }}
        placeholder="Search survey results (e.g., '< 4')"
        value={surveyFilter}
        onChange={onSurveyFilterChange}
        isClearable={true}
        aria-label="Filter survey results"
      />
      <EuiPopover
        button={
          <EuiButton
            iconType="arrowDown"
            iconSide="right"
            onClick={onButtonClick}
          >
            Reports
          </EuiButton>
        }
        isOpen={isPopoverOpen}
        closePopover={closePopover}
      >
        <EuiContextMenu
          initialPanelId={0}
          panels={[
            {
              id: 0,
              title: "Actions",
              items: items,
            },
          ]}
        />
      </EuiPopover>
    </div>
  );
};

export default BauhausAdhoc;
