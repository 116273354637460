import http from "../http-common";

const getCdr = (token, initDate, endDate, page, limit) => {
  return http.get(
    `/api/v1/stats/cdr?initTime=${initDate}&endTime=${endDate}&page=${page}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getCdrById = (token, initDate, endDate, id) => {
  return http.get(
    `/api/v1/stats/callById?initTime=${initDate}&endTime=${endDate}&id=${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const CdrService = {
  getCdr,
  getCdrById,
};

export default CdrService;
