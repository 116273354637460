import "./App.css";
import { Routes, Route, useSearchParams } from "react-router-dom";

import Home from "./pages/home";
import Login from "./pages/login";
import NotFound from "./pages/not-found";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PublicURL from "./pages/public-url";

function App() {
  const navigate = useNavigate();
  const currentURL = window.location.href;

  useEffect(() => {
    let kraken = sessionStorage.getItem("krakenC");
    let token = sessionStorage.getItem("krakenT");

    if (currentURL.includes("shared/outbound")) {
      kraken = "kt";
      token = "tk";
    } else if (!kraken && !token) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/shared/outbound" element={<PublicURL />} />
    </Routes>
  );
}

export default App;
