import React, { useEffect, useState } from "react";
import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiLoadingSpinner,
  EuiAvatar,
  EuiButton,
  EuiButtonIcon,
  EuiSelectable,
  EuiPopover,
  EuiPopoverTitle,
  EuiPopoverFooter,
  EuiTextColor,
  EuiIcon,
  EuiStat,
  EuiFormRow,
  EuiSwitch,
  EuiContextMenu,
} from "@elastic/eui";
import { MdCall } from "react-icons/md";
import RealtimeService from "../services/realtime-service";
import { ThreeDots } from "react-loader-spinner";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { FaHeadphones, FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";

const Realtime = () => {
  const [queues, setQueues] = useState([]);
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const handle = useFullScreenHandle();
  const [isPopoverOpenTags, setIsPopoverOpenTags] = useState(false);

  const [globalMetrics, setGlobalMetrics] = useState({});
  const [showGlobalMetrics, setShowGlobalMetrics] = useState(true);
  const [showQueueTags, setQueuetags] = useState(false);

  const [options, setOptions] = useState([]);
  const [filters, setFilters] = useState([]);

  const [isPopoverOpen, setIsPopoverOpen] = useState({});
  const togglePopover = (agentName) => {
    setIsPopoverOpen((prevState) => ({
      ...prevState,
      [agentName]: !prevState[agentName],
    }));
  };

  const closePopover = (agentName) => {
    setIsPopoverOpen((prevState) => ({
      ...prevState,
      [agentName]: false,
    }));
  };

  const onChange = (e) => {
    setOptions(e);
  };

  const statusColors = {
    Unknown: "#d3d3d3",
    Available: "#00bfb3",
    Busy: "#bd271e",
    "In Call": "#bd271e",
    Invalid: "#d3d3d3",
    Unavailable: "#d3d3d3",
    Ringing: "#9C7E1C",
    "On hold": "#9C7E1C",
  };

  useEffect(() => {
    const token = sessionStorage.getItem("krakenT");

    const fetchQueues = async () => {
      try {
        const response = await RealtimeService.realtime(token);
        const queues = response.data.message?.queues;
        setGlobalMetrics(response.data.message?.globalMetrics);

        const optionsToPush = [];
        const agentsMap = new Map();

        queues.forEach((queue) => {
          optionsToPush.push({
            label: queue.queue.slice(5),
            prepend: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={queue.color}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{
                  width: "16px",
                  height: "16px",
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 6h.008v.008H6V6Z"
                />
              </svg>
            ),
          });
          queue.agents.forEach((agent) => {
            if (!agentsMap.has(agent.name)) {
              const callStartTime = agent.callStartTime
                ? new Date(agent.callStartTime * 1000)
                : null;
              console.log(callStartTime);
              const elapsedTime = callStartTime
                ? Math.floor((Date.now() - callStartTime) / 1000)
                : 0;

              agentsMap.set(agent.name, {
                ...agent,
                queues: [{ name: queue.queue, color: queue.color }],
                callStartTime,
                elapsedTime,
              });
            } else {
              agentsMap.get(agent.name).queues.push({
                name: queue.queue,
                color: queue.color,
              });
            }
          });
        });
        setOptions(optionsToPush);
        setQueues(queues);
        setAgents((prevAgents) => {
          const updatedAgents = Array.from(agentsMap.values()).map((agent) => {
            const prevAgent = prevAgents.find((a) => a.name === agent.name);
            if (prevAgent && prevAgent.inCall && agent.inCall) {
              return { ...agent, elapsedTime: prevAgent.elapsedTime };
            }
            return agent;
          });
          updatedAgents.sort((a, b) => {
            const statusOrder = [
              "Available",
              "Busy",
              "In Call",
              "Ringing",
              "Unavailable",
              "Unknown",
            ];
            return (
              statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
            );
          });
          return updatedAgents;
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchQueues(filters);
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("krakenT");
    const refreshRealtime = async (filteredQueues = []) => {
      try {
        const response = await RealtimeService.realtime(token, filteredQueues);
        const queues = response.data.message?.queues;
        setGlobalMetrics(response.data.message?.globalMetrics);

        const agentsMap = new Map();

        queues.forEach((queue) => {
          queue.agents.forEach((agent) => {
            if (!agentsMap.has(agent.name)) {
              const callStartTime = agent.callStartTime
                ? new Date(agent.callStartTime * 1000)
                : null;
              const elapsedTime = callStartTime
                ? Math.floor((Date.now() - callStartTime.getTime()) / 1000)
                : 0;

              agentsMap.set(agent.name, {
                ...agent,
                queues: [{ name: queue.queue, color: queue.color }],
                callStartTime,
                elapsedTime,
              });
            } else {
              agentsMap.get(agent.name).queues.push({
                name: queue.queue,
                color: queue.color,
              });
            }
          });
        });
        setQueues(queues);
        setAgents((prevAgents) => {
          const updatedAgents = Array.from(agentsMap.values()).map((agent) => {
            const prevAgent = prevAgents.find((a) => a.name === agent.name);
            if (prevAgent && prevAgent.inCall && agent.inCall) {
              return { ...agent, elapsedTime: prevAgent.elapsedTime };
            }
            return agent;
          });
          setLoading(false);
          updatedAgents.sort((a, b) => {
            const statusOrder = [
              "Available",
              "Busy",
              "In Call",
              "Ringing",
              "Unavailable",
              "Unknown",
            ];
            return (
              statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
            );
          });
          return updatedAgents;
        });
      } catch (error) {
        setLoading(false);
      }
    };
    const intervalId = setInterval(() => refreshRealtime(filters), 1500);
    return () => clearInterval(intervalId);
  }, [filters]);
  const applyFilters = () => {
    const filteredQueues = options
      .filter((option) => option.checked === "on")
      .map((option) => option.label);
    setFilters(filteredQueues);
    setLoading(true);
    setIsPopoverOpenTags(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAgents((prevAgents) =>
        prevAgents.map((agent) => {
          if (agent.callStartTime) {
            return {
              ...agent,
              elapsedTime: agent.elapsedTime + 1,
            };
          }
          return agent;
        })
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };
  const onChangeShowGlobalMetrics = (e) => {
    setShowGlobalMetrics(e.target.checked);
  };
  const onChangeShowQueueTags = (e) => {
    setQueuetags(e.target.checked);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: "8px",
      }}
    >
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={true}>
          <EuiPopover
            panelPaddingSize="none"
            button={
              <EuiButton
                color="primary"
                fill
                iconType="arrowDown"
                iconSide="right"
                onClick={() => setIsPopoverOpenTags(!isPopoverOpenTags)}
              >
                Filter Queues
              </EuiButton>
            }
            isOpen={isPopoverOpenTags}
            closePopover={() => setIsPopoverOpenTags(false)}
          >
            <EuiSelectable
              singleSelection={false}
              searchable
              searchProps={{
                placeholder: "Search clients",
                compressed: true,
              }}
              options={options}
              onChange={(newOptions) => setOptions(newOptions)}
            >
              {(list, search) => (
                <div style={{ width: 240 }}>
                  <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
                  {list}
                  <EuiPopoverFooter paddingSize="s">
                    <EuiButton size="s" fullWidth onClick={applyFilters}>
                      Apply Filter{" "}
                    </EuiButton>
                  </EuiPopoverFooter>
                </div>
              )}
            </EuiSelectable>
          </EuiPopover>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow display="columnCompressedSwitch" label="Global Metrics">
            <EuiSwitch
              checked={showGlobalMetrics}
              onChange={onChangeShowGlobalMetrics}
              compressed
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem
          grow={false}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <EuiFormRow display="columnCompressedSwitch" label="Queue Tags">
            <EuiSwitch
              checked={showQueueTags}
              onChange={onChangeShowQueueTags}
              compressed
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            aria-labelledby="tes"
            size="m"
            onClick={handle.enter}
            iconType={"fullScreen"}
          ></EuiButtonIcon>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer></EuiSpacer>
      {loading ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            padding: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EuiLoadingSpinner size="xl" />
        </div>
      ) : (
        <FullScreen handle={handle}>
          <div
            style={{ background: "#f7f8fc", width: "100%", height: "100%" }}
            tabIndex={0}
            className="eui-scrollBar"
          >
            {showGlobalMetrics && (
              <div>
                {/* Modificación en el primer panel para añadir "Service Level" */}
                <EuiFlexGroup>
                  <EuiFlexItem grow={2}>
                    <EuiPanel>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiStat
                            title={globalMetrics.attended_calls}
                            textAlign="left"
                            isLoading={loading}
                            titleColor="success"
                            description={
                              <EuiTextColor color="success">
                                <span>
                                  {globalMetrics.attended_calls_percentage}%
                                </span>
                              </EuiTextColor>
                            }
                          >
                            Attended Calls
                          </EuiStat>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiStat
                            title={globalMetrics.non_attended_calls}
                            textAlign="left"
                            isLoading={loading}
                            titleColor="danger"
                            description={
                              <EuiTextColor color="danger">
                                <span>
                                  {globalMetrics.non_attended_calls_percentage}%
                                </span>
                              </EuiTextColor>
                            }
                          >
                            Non-Attended Calls
                          </EuiStat>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiStat
                            title={`${globalMetrics.service_level}%`}
                            textAlign="left"
                            isLoading={loading}
                            titleColor="primary"
                            description={
                              <EuiTextColor color="primary">
                                <span>Before 45 seconds </span>
                              </EuiTextColor>
                            }
                          >
                            Service Level
                          </EuiStat>
                        </EuiFlexItem>
                        {/* Nuevo KPI */}
                      </EuiFlexGroup>
                    </EuiPanel>
                  </EuiFlexItem>
                  <EuiFlexItem grow={1}>
                    <EuiPanel>
                      <EuiStat
                        title={globalMetrics.calls_on_hold}
                        textAlign="center"
                        isLoading={loading}
                        titleColor="warning"
                        description={"Queued Calls"}
                      />
                    </EuiPanel>
                  </EuiFlexItem>
                  <EuiFlexItem grow={2}>
                    <EuiPanel>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiStat
                            title={formatTime(globalMetrics?.talk_time)}
                            textAlign="left"
                            isLoading={loading}
                            titleColor="accent"
                            description="AHT"
                          >
                            <EuiTextColor color="accent">
                              Average Handle Time
                            </EuiTextColor>
                          </EuiStat>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiStat
                            title={formatTime(globalMetrics?.hold_time)}
                            description="HT"
                            textAlign="left"
                            titleColor="accent"
                            isLoading={loading}
                          >
                            <EuiTextColor color="accent">
                              Ringing Time
                            </EuiTextColor>
                          </EuiStat>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiStat
                            title={
                              <EuiTextColor color="accent">
                                <span>{globalMetrics.survey_listened}%</span>
                              </EuiTextColor>
                            }
                            textAlign="left"
                            isLoading={loading}
                            titleColor="accent"
                            description="Survey listened"
                          >
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <EuiTextColor>
                                {globalMetrics.survey_calls}% scored |
                              </EuiTextColor>
                              <EuiTextColor color="accent">
                                &nbsp;{globalMetrics.survey_avg} AVG
                              </EuiTextColor>
                            </div>
                          </EuiStat>
                        </EuiFlexItem>
                        {/* Nuevo KPI */}
                      </EuiFlexGroup>
                    </EuiPanel>
                  </EuiFlexItem>
                </EuiFlexGroup>

                <EuiSpacer />
              </div>
            )}

            <EuiFlexGroup>
              <EuiFlexItem grow={2}>
                <EuiFlexGrid columns={4} gutterSize={"s"}>
                  {agents.map((agent, i) => {
                    const borderColor = statusColors[agent.status] || "#d3d3d3";
                    const agentName = agent.name.split(" ");
                    const displayName = `${agentName[0]} ${
                      agentName[1] ? agentName[1][0] + "." : ""
                    }`;
                    return (
                      <EuiFlexItem key={i}>
                        <EuiPanel>
                          <EuiFlexGroup
                            alignItems="center"
                            justifyContent="spaceBetween"
                            style={{ position: "relative" }}
                          >
                            <EuiFlexGroup alignItems="center" gutterSize="s">
                              <EuiFlexItem grow={false}>
                                {agent.paused === "1" ? (
                                  <EuiAvatar
                                    size="m"
                                    name={agent.name}
                                    imageUrl={
                                      "https://cdn-icons-png.flaticon.com/512/149/149127.png"
                                    }
                                    iconType="pause"
                                    color="subdued"
                                    iconColor="gray"
                                    style={{
                                      border: `4px solid #9C7E1C`,
                                    }}
                                  />
                                ) : (
                                  <EuiAvatar
                                    size="m"
                                    name={agent.name}
                                    color="subdued"
                                    style={
                                      agent.status === "Ringing"
                                        ? {
                                            animation:
                                              "call 1.5s ease infinite",
                                            border: `4px solid ${borderColor}`,
                                          }
                                        : { border: `4px solid ${borderColor}` }
                                    }
                                  />
                                )}
                              </EuiFlexItem>
                              <EuiFlexItem grow={true} style={{ minWidth: 0 }}>
                                <EuiFlexGroup
                                  alignItems="center"
                                  justifyContent="spaceBetween"
                                >
                                  <EuiFlexItem
                                    grow={true}
                                    style={{ minWidth: 0 }}
                                  >
                                    <EuiTitle
                                      size="xs"
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      <h2>{displayName}</h2>
                                    </EuiTitle>
                                  </EuiFlexItem>
                                  <EuiFlexItem grow={false}>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "2px",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {agent.inCall && (
                                        <EuiPopover
                                          button={
                                            <EuiButtonIcon
                                              size="xs"
                                              iconType={FaHeadphones}
                                              onClick={() =>
                                                togglePopover(agent.name)
                                              }
                                              aria-label="Open actions"
                                            />
                                          }
                                          isOpen={isPopoverOpen[agent.name]}
                                          closePopover={() =>
                                            closePopover(agent.name)
                                          }
                                          anchorPosition="upRight"
                                        >
                                          <EuiContextMenu
                                            initialPanelId={0}
                                            panels={[
                                              {
                                                id: 0,
                                                items: [
                                                  {
                                                    name: "Activa",
                                                    icon: <FaMicrophone />,
                                                    onClick: () => {
                                                      /* lógica para escucha activa */ closePopover(
                                                        agent.name
                                                      );
                                                    },
                                                  },
                                                  {
                                                    name: "Pasiva",
                                                    icon: <FaMicrophoneSlash />,
                                                    onClick: () => {
                                                      /* lógica para escucha pasiva */ closePopover(
                                                        agent.name
                                                      );
                                                    },
                                                  },
                                                ],
                                              },
                                            ]}
                                            style={{
                                              minWidth: 150,
                                              height: 80,
                                            }} // Ajusta el tamaño del popover
                                          />
                                        </EuiPopover>
                                      )}
                                    </div>
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                                <EuiText size="xs">
                                  <p>
                                    {agent.paused === "1" ? (
                                      agent.pausedReason
                                    ) : agent.status === "Ringing" ? (
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          textAlign: "start",
                                          gap: "4px",
                                        }}
                                      >
                                        <MdCall />|
                                        <ThreeDots
                                          height="15"
                                          width="15"
                                          radius="6"
                                          color="#9C7E1C"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClassName=""
                                          visible={true}
                                        />
                                        |
                                        <ThreeDots
                                          height="15"
                                          width="15"
                                          radius="6"
                                          color="#9C7E1C"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClassName=""
                                          visible={true}
                                        />
                                      </span>
                                    ) : (
                                      agent.status
                                    )}
                                  </p>
                                </EuiText>
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </EuiFlexGroup>
                        </EuiPanel>
                      </EuiFlexItem>
                    );
                  })}
                </EuiFlexGrid>
              </EuiFlexItem>
              <EuiFlexItem grow={2}>
                <EuiFlexGrid columns={3} gutterSize={"s"}>
                  {queues.map((queue, i) => {
                    return (
                      <EuiFlexItem key={i}>
                        <EuiPanel>
                          <EuiFlexGroup
                            alignItems="center"
                            justifyContent="spaceBetween"
                          >
                            <EuiFlexItem>
                              <EuiTitle
                                size="xs"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <h2>{queue.queue.slice(5)}</h2>
                              </EuiTitle>
                            </EuiFlexItem>
                            {showQueueTags && (
                              <EuiFlexItem grow={false}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill={queue.color}
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  style={{ width: "16px", height: "16px" }}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 6h.008v.008H6V6Z"
                                  />
                                </svg>
                              </EuiFlexItem>
                            )}
                          </EuiFlexGroup>

                          <EuiSpacer size="xs" />
                          <EuiFlexGrid columns={2}>
                            <EuiFlexItem grow={true}>
                              <div
                                style={{
                                  display: "inline-block",
                                  width: "48px",
                                  height: "48px",
                                  borderRadius: "50%",
                                  border:
                                    parseInt(queue.calls_on_hold_quantity) === 0
                                      ? "solid 3px #d3d3d3"
                                      : "solid 3px #8a6a0a",
                                  backgroundColor:
                                    parseInt(queue.calls_on_hold_quantity) === 0
                                      ? "#F1F4FA"
                                      : "#f1d86f",
                                  animation:
                                    parseInt(queue.calls_on_hold_quantity) === 0
                                      ? ""
                                      : "call 1.5s ease infinite",
                                  textAlign: "center",
                                  lineHeight: "42px",
                                }}
                              >
                                <b>{queue.calls_on_hold_quantity}</b>
                              </div>
                              <EuiSpacer size="s" />
                              <EuiText size="xs">
                                Free agents {queue.agents_available}/
                                {queue.agents_logged}
                              </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                              <EuiText size="xs" textAlign="right">
                                Attended: {queue.attended_calls}
                              </EuiText>
                              <EuiText size="xs" textAlign="right">
                                Non-attended: {queue.non_attended_calls}
                              </EuiText>
                              <EuiText size="xs" textAlign="right">
                                AHT:{" "}
                                {new Date(queue?.talk_time * 1000)
                                  .toISOString()
                                  .substring(14, 19)}
                              </EuiText>
                              <EuiText size="xs" textAlign="right">
                                Hold time:{" "}
                                {new Date(queue?.hold_time * 1000)
                                  .toISOString()
                                  .substring(14, 19)}
                              </EuiText>
                            </EuiFlexItem>
                          </EuiFlexGrid>
                        </EuiPanel>
                      </EuiFlexItem>
                    );
                  })}
                </EuiFlexGrid>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
        </FullScreen>
      )}
    </div>
  );
};

export default Realtime;
