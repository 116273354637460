import React, { Fragment, useEffect, useState, useRef } from "react";
import ReactAudioPlayer from "react-audio-player";
import {
  EuiBasicTable,
  EuiTitle,
  EuiIcon,
  EuiHealth,
  EuiSpacer,
  EuiHorizontalRule,
  EuiText,
  EuiLoadingChart,
  EuiDatePicker,
  EuiButton,
  EuiDatePickerRange,
  EuiDescriptionList,
  EuiScreenReaderOnly,
  RIGHT_ALIGNMENT,
  EuiButtonIcon,
  EuiLoadingSpinner,
  EuiResizableContainer,
  EuiPopover,
  EuiModal,
  EuiModalHeaderTitle,
  EuiModalHeader,
  EuiFlexGrid,
  EuiModalBody,
  EuiCallOut,
  EuiFlexGroup,
  EuiInMemoryTable,
  EuiPanel,
  EuiToolTip,
} from "@elastic/eui";
import {
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiCopy,
  useGeneratedHtmlId,
} from "@elastic/eui";
import { MdOutlinePhoneCallback } from "react-icons/md";
import ReactCountryFlag from "react-country-flag";
import CdrService from "../services/cdr-service";
import { EuiFieldSearch, EuiSwitch } from "@elastic/eui";
import LoginService from "../services/login-service";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FaFileCsv } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import { FaFileExcel } from "react-icons/fa";

import { CSVLink, CSVDownload } from "react-csv";
import StatsService from "../services/stats-service";
import axios from "axios";
import BauhausAdhoc from "./BauhausAdHoc";
/*
Example user object:

{
  id: '1',
  firstName: 'john',
  lastName: 'doe',
  github: 'johndoe',
  dateOfBirth: Date.now(),
  nationality: 'NL',
  online: true
}

Example country object:

{
  code: 'NL',
  name: 'Netherlands',
  flag: '🇳🇱'
}
*/

export const Cdr = () => {
  const [showCallRecordingModal, setShowCallRecordingModal] = useState(false);
  const [urlGrabacion, setUrlGrabacion] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [isClearable, setIsClearable] = useState(true);
  const [value, setValue] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().add(-24, "h"));
  const [endDate, setEndDate] = useState(moment());
  const [company, setCompany] = useState();
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({});
  const [isCallRecordingErrored, setIsCallRecordingErrored] = useState(false);
  const [isCallRecordingLoading, setIsCallRecordingLoading] = useState(false);
  const [cdrLoading, setCdrLoading] = useState(false);
  const [cdrErrored, setCdrErrored] = useState(false);
  const navigate = useNavigate();

  const [cdrListPlain, setCdrListPlain] = useState([]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const [surveyFilter, setSurveyFilter] = useState("");

  const onSurveyFilterChange = (e) => {
    setSurveyFilter(e.target.value);
  };

  const intervalRef = useRef();
  const [pageSize, setPageSize] = useState(15);
  const [showPerPageOptions, setShowPerPageOptions] = useState(true);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [sortField, setSortField] = useState("answeringAgent");
  const [sortDirection, setSortDirection] = useState("asc");
  const [pit, setPit] = useState();
  const [cdrList, setCdrList] = useState([
    {
      initDate: (
        <>
          <EuiText size="s">
            <EuiLoadingChart size="m" mono />
            &nbsp;&nbsp;Loading...
          </EuiText>
        </>
      ),
      id: "",
      endDate: "",
      durationTime: "",
      ringingTime: "",
      srcNumber: "",
      dstNumber: "",
      queueName: "",
      queueResult: "",
      answeringAgent: "",
      whoHangup: "",
      callRecording: "",
    },
  ]);

  const closeModalRecording = () => setShowCallRecordingModal(false);

  const filterClicked = () => {
    setIsLoading(true);
    setPageIndex(0);
    setCdrList([
      {
        initDate: (
          <>
            <EuiText size="s">
              <EuiLoadingChart size="m" mono />
              &nbsp;&nbsp;Loading...
            </EuiText>
          </>
        ),
        id: "",
        endDate: "",
        durationTime: "",
        ringingTime: "",
        srcNumber: "",
        dstNumber: "",
        queueName: "",
        queueResult: "",
        answeringAgent: "",
        whoHangup: "",
        callRecording: "",
      },
    ]);
    const token = sessionStorage.getItem("krakenT");
    const initDate = startDate.toISOString();
    const endedDate = endDate.toISOString();
    const page = pageIndex + 1;
    CdrService.getCdr(token, initDate, endedDate, page, pageSize)
      .then((response) => {
        setTotalItemCount(response.data.message.totalCalls);
        setCdrList(response.data.message.calls);
        setIsLoading(false);
      })
      .catch((error) => {
        LoginService.logout();
        navigate("/login");
      });

    CdrService.getCdr(token, initDate, endedDate, 1, 100000)
      .then((response) => {
        setCdrListPlain(response.data.message.calls);
      })
      .catch((error) => {
        LoginService.logout();
        navigate("/login");
      });
  };

  useEffect(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    setIsLoading(true);
    const companyToSet = localStorage.getItem("CN");
    setCompany(companyToSet);
    const token = sessionStorage.getItem("krakenT");
    const initDate = startDate.toISOString();
    const endedDate = endDate.toISOString();
    const page = pageIndex + 1;
    CdrService.getCdr(token, initDate, endedDate, page, pageSize)
      .then((response) => {
        setTotalItemCount(response.data.message.totalCalls);
        setCdrList(response.data.message.calls);
        setIsLoading(false);
      })
      .catch((error) => {
        LoginService.logout();
        navigate("/login");
      });

    // CdrService.getCdr(token, initDate, endedDate, 1, 100000)
    //   .then((response) => {
    //     setCdrListPlain(response.data.message.calls);
    //   })
    //   .catch((error) => {
    //     LoginService.logout();
    //     navigate("/login");
    //   });
  }, []);

  const onTableChange = ({ page = {} }) => {
    setIsLoading(true);
    setCdrList([
      {
        initDate: (
          <>
            <EuiText size="s">
              <EuiLoadingChart size="m" mono />
              &nbsp;&nbsp;Loading...
            </EuiText>
          </>
        ),
        id: "",
        endDate: "",
        durationTime: "",
        ringingTime: "",
        srcNumber: "",
        dstNumber: "",
        queueName: "",
        queueResult: "",
        answeringAgent: "",
        whoHangup: "",
        callRecording: "",
      },
    ]);
    const { index: pageIndex, size: pageSize } = page;
    setPageIndex(pageIndex);
    setPageSize(pageSize);
    const token = sessionStorage.getItem("krakenT");
    const initDate = startDate.toISOString();
    const endedDate = endDate.toISOString();
    const indexx = pageIndex + 1;
    CdrService.getCdr(token, initDate, endedDate, indexx, pageSize)
      .then((response) => {
        setTotalItemCount(response.data.message.totalCalls);
        setCdrList(response.data.message.calls);
        setIsLoading(false);
      })
      .catch((error) => {
        LoginService.logout();
        navigate("/login");
      });
  };

  const renderStatus = (callResult) => {
    var color;

    if (callResult === "Attended") {
      color = "success";
    }

    if (callResult === "Non Attended") {
      color = "danger";
    }
    if (callResult === "Canceled") {
      color = "FAA608";
    }
    if (callResult === "Timeout") {
      color = "danger";
    }
    if (callResult === "Abandon") {
      color = "FAA608";
    }

    return (
      <>{callResult ? <EuiHealth color={color}>{callResult}</EuiHealth> : ""}</>
    );
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  const toggleDetails = (item) => {
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
    if (itemIdToExpandedRowMapValues[item.id]) {
      delete itemIdToExpandedRowMapValues[item.id];
      setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
    } else {
      const token = sessionStorage.getItem("krakenT");
      const initDate = startDate.toISOString();
      const endedDate = endDate.toISOString();
      CdrService.getCdrById(token, initDate, endedDate, item.id)
        .then((response) => {
          const listItems = [];
          for (let event of response.data.message.data) {
            listItems.push({
              title: formatDate(new Date(Date.parse(event.initDate))),
              description: event.event,
            });
          }

          itemIdToExpandedRowMapValues[item.id] = (
            <>
              <div>
                <div style={{ marginTop: 5 }}>
                  <EuiTitle size="s">
                    <h1>
                      <MdOutlinePhoneCallback />
                      &nbsp;&nbsp;CALL EVENTS
                    </h1>
                  </EuiTitle>
                </div>
                <div style={{ marginTop: 8, marginLeft: -30 }}>
                  <EuiDescriptionList
                    style={{ maxWidth: "400px" }}
                    gutterSize="xs"
                    textStyle="reverse"
                    align="center"
                    listItems={listItems}
                    compressed={true}
                    type="column"
                  />
                </div>
              </div>
            </>
          );
          setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
        })
        .catch((error) => {});
    }
  };

  const [dateSelected, setDateSelected] = useState();
  const [srcSelected, setSrcSelected] = useState();
  const [dstSelected, setDstSelected] = useState();
  const [agentSelected, setAgentSelected] = useState();
  const [idSelected, setIdSelected] = useState();
  const [callRecordingSelected, setCallRecordingSelected] = useState();

  const [isDownloadLoading, setIsDownloadLoading] = useState();
  const [showDownloadButton, setShowDownloadButton] = useState(true);

  const [isDownloadingErrored, setIsDownloadLoadingErrored] = useState(false);

  const callRecordingClicked = async (item) => {
    try {
      console.log(item);
      setIsDownloadLoadingErrored(false);
      setShowDownloadButton(true);
      setIsDownloadLoading(true);
      setIsCallRecordingErrored(false);
      setShowCallRecordingModal(true);
      setIsCallRecordingLoading(true);
      const token = sessionStorage.getItem("krakenT");
      const response = await axios.get(
        `/api/v1/stats/callRecording?id=${
          item.id.startsWith("vhsp") ? item.callRecording : item.id
        }`,
        // `https://moovecars.kunzite.app:4002/getRec?id=1676974462.59357`,

        {
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            // token: "6EptF3jHAxegQa9HPpbmG8sRNQkt2kv1",
          },
        }
      );

      const audioUrl = window.URL.createObjectURL(new Blob([response.data]));

      setUrlGrabacion(audioUrl);
      setIsCallRecordingLoading(false);
      setDateSelected(formatDate(new Date(Date.parse(item.initDate))));
      setSrcSelected(item.srcNumber);
      setDstSelected(item.dstNumber);
      setAgentSelected(item.agent);
      setIdSelected(item.id);
      setCallRecordingSelected(item.callRecording);
      setIsDownloadLoading(false);
    } catch (error) {
      setShowDownloadButton(false);
      setIsDownloadLoading(false);
      setIsCallRecordingErrored(true);
      setIsCallRecordingLoading(false);
    }
  };

  const downloadClicked = async () => {
    try {
      setIsDownloadLoading(true);
      const token = sessionStorage.getItem("krakenT");
      const response = await axios.get(
        `/api/v1/stats/callRecordingDownload?id=${
          idSelected.startsWith("vhsp") ? callRecordingSelected : idSelected
        }`,
        // `https://moovecars.kunzite.app:4002/getRec?id=1676974462.59357`,

        {
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            // token: "6EptF3jHAxegQa9HPpbmG8sRNQkt2kv1",
          },
        }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      var tempLink = document.createElement("a");
      tempLink.href = downloadUrl;
      tempLink.setAttribute("download", `${srcSelected}-${agentSelected}.mp3`);
      tempLink.click();
      setIsDownloadLoading(false);
    } catch (error) {
      console.log(error);
      setIsDownloadLoadingErrored(true);
      setIsDownloadLoading(false);
    }
  };
  const columns = [
    {
      align: RIGHT_ALIGNMENT,
      width: "40px",
      isExpander: true,
      name: (
        <EuiScreenReaderOnly>
          <span>Expand rows</span>
        </EuiScreenReaderOnly>
      ),
      render: (item) => (
        <EuiButtonIcon
          onClick={() => toggleDetails(item)}
          aria-label={itemIdToExpandedRowMap[item.id] ? "Collapse" : "Expand"}
          iconType={itemIdToExpandedRowMap[item.id] ? "arrowUp" : "arrowDown"}
        />
      ),
    },

    {
      name: "Init Date",
      truncateText: true,
      sortable: true,
      render: (item) => (
        <EuiText size="s">
          {isLoading ? (
            <>
              <EuiText size="s">
                <EuiLoadingChart size="m" mono />
                &nbsp;&nbsp;Loading...
              </EuiText>
            </>
          ) : (
            <>{formatDate(new Date(Date.parse(item.initDate)))} </>
          )}
        </EuiText>
      ),
    },
    {
      name: "End Date",
      sortable: true,
      truncateText: true,
      render: (item) => (
        <EuiText size="s">
          {item.endDate ? (
            <>{formatDate(new Date(Date.parse(item.endDate)))} </>
          ) : (
            ""
          )}
        </EuiText>
      ),
    },
    {
      name: "Direction",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.direction}</EuiText>,
    },
    {
      name: "Duration Time",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.durationTime}</EuiText>,
    },

    {
      name: "Ringing Time",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.ringingTime}</EuiText>,
    },
    {
      name: "Src Number",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.srcNumber}</EuiText>,
    },

    {
      name: "Dst Number",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.dstNumber}</EuiText>,
    },
    {
      name: "Queue Name",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.queueName}</EuiText>,
    },
    {
      field: "callResult",
      name: "Result",
      truncateText: true,
      sortable: true,
      render: (callResult) => renderStatus(callResult),
      mobileOptions: {
        show: false,
      },
    },
    {
      name: "Agent",
      truncateText: true,
      render: (item) => (
        <EuiText size="s">
          {item.agent ? (
            <>
              <EuiIcon type="userAvatar" />
              &nbsp;{item.agent}
            </>
          ) : (
            ""
          )}
        </EuiText>
      ),
    },
    {
      name: "Transfered",
      truncateText: true,
      render: (item) => (
        <EuiText size="s">{item.transfered ? "Si" : "No"}</EuiText>
      ),
    },

    {
      name: "Agent Transfered",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.agentTransfered}</EuiText>,
    },
    {
      name: "Who Hangup",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.whoHangup}</EuiText>,
    },
    {
      name: "Surveys",
      truncateText: true,
      trim: true,
      render: (item) => (
        <>
          {item.survey && item.survey.length >= 1 ? (
            <EuiToolTip
              position="top"
              content={
                <div>
                  {item.survey.map((surveyItem, index) => (
                    <div key={index}>
                      {surveyItem.titulo}: {surveyItem.nota}
                    </div>
                  ))}
                </div>
              }
            >
              <EuiButtonIcon
                display="base"
                iconType="list"
                aria-label="Ver Encuestas"
                onClick={() => {
                  /* Implementa la acción al hacer clic, si es necesaria */
                }}
              />
            </EuiToolTip>
          ) : (
            <EuiButtonIcon
              display="base"
              iconType="cross" // Cambiado a un icono de 'X'
              aria-label="No hay encuestas"
              color="danger" // Cambia el color para destacar que no hay encuestas
              onClick={() => {
                /* Puedes implementar una acción aquí si es necesario, como mostrar un mensaje */
              }}
            />
          )}
        </>
      ),
    },
    {
      name: "Call Recording",
      truncateText: true,
      render: (item) => (
        <EuiButtonIcon
          display="base"
          iconType="play"
          aria-label="play"
          onClick={() => callRecordingClicked(item)}
        />
      ),
    },
  ];

  const columns2 = [
    {
      align: RIGHT_ALIGNMENT,
      width: "40px",
      isExpander: true,
      name: (
        <EuiScreenReaderOnly>
          <span>Expand rows</span>
        </EuiScreenReaderOnly>
      ),
      render: (item) => (
        <EuiButtonIcon
          onClick={() => toggleDetails(item)}
          aria-label={itemIdToExpandedRowMap[item.id] ? "Collapse" : "Expand"}
          iconType={itemIdToExpandedRowMap[item.id] ? "arrowUp" : "arrowDown"}
        />
      ),
    },

    {
      name: "Init Date",
      truncateText: true,
      sortable: true,
      render: (item) => (
        <EuiText size="s">
          {isLoading ? (
            <>
              <EuiText size="s">
                <EuiLoadingChart size="m" mono />
                &nbsp;&nbsp;Loading...
              </EuiText>
            </>
          ) : (
            <>{formatDate(new Date(Date.parse(item.initDate)))} </>
          )}
        </EuiText>
      ),
    },
    {
      name: "End Date",
      truncateText: true,
      sortable: true,
      render: (item) => (
        <EuiText size="s">
          {item.endDate ? (
            <>{formatDate(new Date(Date.parse(item.endDate)))} </>
          ) : (
            ""
          )}
        </EuiText>
      ),
    },
    {
      name: "Direction",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.direction}</EuiText>,
    },
    {
      name: "Duration Time",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.durationTime}</EuiText>,
    },

    {
      name: "Ringing Time",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.ringingTime}</EuiText>,
    },
    {
      name: "Src Number",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.srcNumber}</EuiText>,
    },
    {
      name: "Dst Number",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.dstNumber}</EuiText>,
    },
    {
      name: "Queue Name",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.queueName}</EuiText>,
    },
    {
      field: "callResult",
      name: "Result",
      truncateText: true,
      sortable: true,
      render: (callResult) => renderStatus(callResult),
      mobileOptions: {
        show: false,
      },
    },
    {
      name: "Agent",
      truncateText: true,
      render: (item) => (
        <EuiText size="s">
          {item.agent ? (
            <>
              <EuiIcon type="userAvatar" />
              &nbsp;{item.agent}
            </>
          ) : (
            ""
          )}
        </EuiText>
      ),
    },
    {
      name: "Who Hangup",
      truncateText: true,
      render: (item) => <EuiText size="s">{item.whoHangup}</EuiText>,
    },
  ];

  const headers = [
    {
      label: "Init Date",
      key: "initDate",
    },
    {
      label: "End Date",
      key: "endDate",
    },
    {
      label: "Direction",
      key: "direction",
    },
    {
      label: "Duration Time",
      key: "durationTime",
    },
    {
      label: "Ringing Time",
      key: "ringingTime",
    },
    {
      label: "Src Number",
      key: "srcNumber",
    },
    {
      label: "Dst Number",
      key: "dstNumber",
    },
    {
      label: "Queue Name",
      key: "queueName",
    },
    {
      label: "Result",
      key: "result",
    },
    {
      label: "Agent",
      key: "agent",
    },
    {
      label: "Who Hangup",
      key: "whoHangup",
    },
  ];

  const uniqueSurveyQuestions = new Set();
  cdrList.forEach((item) => {
    if (item.survey) {
      item.survey.forEach((s) => {
        uniqueSurveyQuestions.add(s.titulo);
      });
    }
  });

  // Crear los encabezados dinámicamente
  const dynamicHeaders = Array.from(uniqueSurveyQuestions).map((question) => ({
    label: `Survey_${question || "unknown"}`,
    key: `survey_${question?.replace(/\s/g, "_")}`,
  }));

  const headers2 = [
    {
      label: "Init Date",
      key: "initDate",
    },
    {
      label: "End Date",
      key: "endDate",
    },
    {
      label: "Direction",
      key: "direction",
    },
    {
      label: "Duration Time",
      key: "durationTime",
    },
    {
      label: "Ringing Time",
      key: "ringingTime",
    },
    {
      label: "Src Number",
      key: "srcNumber",
    },
    {
      label: "Dst Number",
      key: "dstNumber",
    },
    {
      label: "Queue Name",
      key: "queueName",
    },
    {
      label: "Result",
      key: "callResult",
    },
    {
      label: "Agent",
      key: "agent",
    },
    {
      label: "Who Hangup",
      key: "whoHangup",
    },
    {
      label: "Transfered",
      key: "transfered",
    },
    { label: "Agent transfered", key: "agentTransfered" },
    ...dynamicHeaders,
  ];
  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [5, 10, 15, 20],
    showPerPageOptions,
  };

  const resultsCount =
    pageSize === 0 ? (
      <strong>All</strong>
    ) : (
      <>
        <strong>
          {pageSize * pageIndex + 1}-{pageSize * pageIndex + pageSize}
        </strong>{" "}
        of {totalItemCount}
      </>
    );
  const [isPopoverOpen, setPopover] = useState(false);

  const smallContextMenuPopoverId = useGeneratedHtmlId({
    prefix: "smallContextMenuPopover",
  });

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const formattedData = cdrList.map((item) => {
    const newItem = { ...item };

    // Convert Init Date and End Date to Madrid Time Zone
    if (newItem.initDate) {
      newItem.initDate = formatDate(new Date(Date.parse(item.initDate)));
    }

    if (newItem.endDate) {
      newItem.endDate = formatDate(new Date(Date.parse(item.endDate)));
    }

    if (newItem.survey && newItem.survey.length) {
      newItem.survey.forEach((s) => {
        const surveyKey = `survey_${s?.titulo?.replace(/\s/g, "_")}`;
        newItem[surveyKey] = s?.nota;
      });
    } else {
      dynamicHeaders.forEach((header) => {
        newItem[header.key] = "No Data";
      });
    }
    return newItem;
  });

  const items = [
    <EuiContextMenuItem key="csv" onClick={closePopover}>
      <FaFileCsv />
      <CSVLink
        data={formattedData}
        headers={headers2}
        filename={`${company}_EXPORT.csv`}
      >
        <b> Csv</b>
      </CSVLink>
    </EuiContextMenuItem>,
    <EuiContextMenuItem disabled key="edit" onClick={closePopover}>
      <FaFileExcel />
      <b> Excel</b>
    </EuiContextMenuItem>,
    <EuiContextMenuItem disabled key="share" onClick={closePopover}>
      <FaFilePdf /> <b> Pdf</b>
    </EuiContextMenuItem>,
  ];

  const button = (
    <EuiButton onClick={onButtonClick}>
      <EuiIcon type="exportAction" />
      Export
      <EuiIcon type="arrowDown" />
    </EuiButton>
  );

  const filteredCdrList = cdrList.filter((item) => {
    if (!surveyFilter) return true;
    const [operator, value] = surveyFilter.split(" ");
    const numericValue = parseFloat(value);
    return item?.survey?.some((surveyItem) => {
      if (operator === ">") {
        return surveyItem.nota > numericValue;
      } else if (operator === "<") {
        return surveyItem.nota < numericValue;
      } else if (operator === "=") {
        return surveyItem.nota === numericValue;
      }
      return false;
    });
  });

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: "flex" }}>
        <div>
          <EuiTitle size="m">
            <h1>Call Detail Records</h1>
          </EuiTitle>
        </div>
        {/* <div
          style={{
            marginRight: 10,
            marginLeft: "auto",
          }}
        >
          <div
            style={{
              marginRight: 10,
              marginLeft: "auto",
            }}
          >
            <EuiText size="xs">
              Type: You can use wildcards in orther to filter by
              source/destination. Example: +34*
            </EuiText>
          </div>
          <div
            style={{
              marginRight: 0,
              marginLeft: "auto",
              height: 20,
              marginTop: 10,
            }}
          >
            <EuiFieldSearch
              style={{
                marginRight: 0,
                marginLeft: "auto",
                height: 20,
              }}
              size="xs"
              placeholder="Search by source / destination"
              value={value}
              isLoading={isLoading}
              onChange={(e) => onChange(e)}
              isClearable={isClearable}
              aria-label="Use aria labels when no actual label is in use"
            />
          </div>
        </div> */}
        <div
          style={{
            marginRight: 0,
            marginLeft: "auto",
            display: "flex",
            height: 40,
            marginBottom: 0,
            marginTop: "auto",
          }}
        >
          <EuiDatePickerRange
            isInvalid={startDate > endDate}
            startDateControl={
              <EuiDatePicker
                selected={startDate}
                onChange={(date) => date && setStartDate(date)}
                startDate={startDate}
                endDate={endDate}
                aria-label="Start date"
                showTimeSelect
              />
            }
            endDateControl={
              <EuiDatePicker
                selected={endDate}
                onChange={(date) => date && setEndDate(date)}
                startDate={startDate}
                endDate={endDate}
                aria-label="End date"
                showTimeSelect
              />
            }
          />
          &nbsp;
          <EuiButton
            style={{
              marginRight: 0,
              marginLeft: 10,
              display: "flex",
              height: 38,
              marginBottom: 0,
            }}
            fill
            iconType="lensApp"
            onClick={() => {
              filterClicked();
            }}
          >
            Apply Filters
          </EuiButton>
        </div>
        <div style={{ marginLeft: 20, marginRight: 10 }}>
          <EuiPopover
            id={smallContextMenuPopoverId}
            button={button}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="none"
            anchorPosition="downLeft"
          >
            <EuiContextMenuPanel size="s" items={items} />
          </EuiPopover>
        </div>
      </div>
      <div>
        {/* <EuiText size="xs">
          Showing {resultsCount} <strong>Calls</strong>
        </EuiText>
        <EuiSpacer size="s" />
        <EuiHorizontalRule
          margin="none"
          style={{ height: 2, marginRight: 10 }}
        /> */}

        <Fragment>
          <div
            style={{
              marginTop: "1em",

              marginRight: "1.5em",
            }}
          >
            <EuiPanel>
              <EuiInMemoryTable
                tableCaption="cdr-table"
                itemId="id"
                itemIdToExpandedRowMap={itemIdToExpandedRowMap}
                error={cdrErrored}
                loading={cdrLoading}
                items={filteredCdrList}
                columns={
                  localStorage.getItem("CN") === "Geoatlanter"
                    ? columns2
                    : columns
                }
                search={{
                  toolsRight:
                    localStorage.getItem("CN") === "Bauhaus España" ? (
                      <BauhausAdhoc
                        surveyFilter={surveyFilter}
                        onSurveyFilterChange={onSurveyFilterChange}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    ) : (
                      ""
                    ),
                  box: {
                    incremental: true,
                    placeholder:
                      "You can filter by any word (agent, call result, address, etc). In addition you can choose several words, e.g.: attended inbound",
                    schema: true,
                  },
                }}
                pagination={true}
                sorting={true}
              />
            </EuiPanel>
          </div>
        </Fragment>
      </div>
      {showCallRecordingModal && (
        <EuiModal onClose={closeModalRecording}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Call Recording</EuiModalHeaderTitle>
          </EuiModalHeader>
          <div
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
            }}
          ></div>
          {isCallRecordingLoading ? (
            <EuiModalBody>
              <EuiLoadingSpinner></EuiLoadingSpinner>
            </EuiModalBody>
          ) : (
            <EuiModalBody>
              {isCallRecordingErrored ? (
                <EuiCallOut
                  title="Sorry, there was an error"
                  color="danger"
                  iconType="alert"
                >
                  <p>
                    Insufficient permissions to perform this action, please
                    contact your administrator
                  </p>
                </EuiCallOut>
              ) : (
                <>
                  <b>Date: </b>
                  {dateSelected}
                  <br></br>
                  <b>Source: </b>
                  {srcSelected}
                  <br></br>
                  <b>Destination: </b>
                  {dstSelected}
                  <br></br>
                  <b>Agent: </b>
                  {agentSelected}
                  <br></br>
                  <EuiSpacer></EuiSpacer>
                  <ReactAudioPlayer
                    src={urlGrabacion}
                    controls={true}
                    controlsList={"nodownload"}
                  />
                </>
              )}
            </EuiModalBody>
          )}
          {isDownloadingErrored ? (
            <>
              <EuiSpacer></EuiSpacer>
              <EuiCallOut
                title="Insufficient permissions to perform this action, please contact your administrator"
                color="danger"
                iconType="alert"
                size="s"
                style={{
                  width: 300,
                  marginLeft: "10%",
                  marginRight: "10%",
                }}
              ></EuiCallOut>
              <EuiSpacer></EuiSpacer>
            </>
          ) : (
            <EuiSpacer></EuiSpacer>
          )}

          {showDownloadButton ? (
            <EuiFlexGroup>
              <EuiButton
                style={{
                  width: 100,
                  marginRight: "-5%",
                  marginLeft: "auto",
                  marginBottom: "2%",
                }}
                fill
                color="success"
                iconType="download"
                onClick={downloadClicked}
                isLoading={isDownloadLoading}
              >
                Download
              </EuiButton>
              <EuiButton
                style={{
                  width: 100,
                  marginRight: "5%",
                  marginLeft: 0,
                  marginBottom: "2%",
                }}
                onClick={closeModalRecording}
                fill
              >
                Close
              </EuiButton>
            </EuiFlexGroup>
          ) : (
            <EuiButton
              style={{
                width: 100,
                marginRight: "5%",
                marginLeft: "auto",
                marginBottom: "2%",
              }}
              onClick={closeModalRecording}
              fill
            >
              Close
            </EuiButton>
          )}
          <EuiSpacer size="xs"></EuiSpacer>
        </EuiModal>
      )}
    </div>
  );
};
