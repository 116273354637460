import http from "../http-common";

const getCallsByQueue = (token, initDate, endDate, trame) => {
  const trameToFilter = trame;
  return http.get(
    trameToFilter
      ? `/api/v1/stats/getCallsByQueue?initTime=${initDate}&endTime=${endDate}&trame=${trameToFilter}`
      : `/api/v1/stats/getCallsByQueue?initTime=${initDate}&endTime=${endDate}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getCallsByQueueHourly = (token, initDate, endDate, trame) => {
  const trameToFilter = trame;
  return http.get(
    trameToFilter
      ? `/api/v1/stats/getCallsByQueue/hourly?initTime=${initDate}&endTime=${endDate}&trame=${trameToFilter}`
      : `/api/v1/stats/getCallsByQueue/hourly?initTime=${initDate}&endTime=${endDate}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getAvgsByQueue = (token, initDate, endDate, trame) => {
  const trameToFilter = trame;
  return http.get(
    trameToFilter
      ? `/api/v1/stats/getTimingsByQueue?initTime=${initDate}&endTime=${endDate}&trame=${trameToFilter}`
      : `/api/v1/stats/getTimingsByQueue?initTime=${initDate}&endTime=${endDate}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getAvgsByTrame = (token, initDate, endDate, trame) => {
  const trameToFilter = trame;
  return http.get(
    trameToFilter
      ? `/api/v1/stats/getTimingsByQueue/hourly?initTime=${initDate}&endTime=${endDate}&trame=${trameToFilter}`
      : `/api/v1/stats/getTimingsByQueue/hourly?initTime=${initDate}&endTime=${endDate}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getCallsByAgent = (token, initDate, endDate, trame) => {
  const trameToFilter = trame;
  return http.get(
    trameToFilter
      ? `/api/v1/stats/getCallsByAgent?initTime=${initDate}&endTime=${endDate}&trame=${trameToFilter}`
      : `/api/v1/stats/getCallsByAgent?initTime=${initDate}&endTime=${endDate}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getAttendedByAgent = (token, initDate, endDate, trame) => {
  const trameToFilter = trame;
  return http.get(
    trameToFilter
      ? `/api/v1/stats/getAttendedByAgent?initTime=${initDate}&endTime=${endDate}&trame=${trameToFilter}`
      : `/api/v1/stats/getAttendedByAgent?initTime=${initDate}&endTime=${endDate}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// OUTBOUND

const getCallsByAgentOut = (token, initDate, endDate, agentsToFilter) => {
  return http.post(
    `/api/v1/stats/outbound/getCallsByAgent?initTime=${initDate}&endTime=${endDate}`,
    {
      agents: agentsToFilter,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const getCallsByAgentOutDetailed = (
  token,
  initDate,
  endDate,
  agentsToFilter
) => {
  console.log(agentsToFilter);
  return http.post(
    `/api/v1/stats/outbound/getCallsByAgentDetailed?initTime=${initDate}&endTime=${endDate}`,
    {
      agents: agentsToFilter,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getCallsByAgentHourlyOut = (token, initDate, endDate, agentsToFilter) => {
  return http.post(
    `/api/v1/stats/outbound/hourly/getCallsByAgentHourlyOut?initTime=${initDate}&endTime=${endDate}`,
    {
      agents: agentsToFilter,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getAttendedByAgentHourlyOut = (
  token,
  initDate,
  endDate,
  agentsToFilter
) => {
  return http.post(
    `/api/v1/stats/outbound/hourly/getAttendedCallsByAgentHourlyOut?initTime=${initDate}&endTime=${endDate}`,
    {
      agents: agentsToFilter,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getEffectiveCalls = (token, initDate, endDate, agentsToFilter) => {
  return http.post(
    `/api/v1/stats/outbound/getEffectiveCalls?initTime=${initDate}&endTime=${endDate}`,
    {
      agents: agentsToFilter,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getTimingsByAgentOut = (token, initDate, endDate, agentsToFilter) => {
  return http.post(
    `/api/v1/stats/outbound/getTimingsByAgentOut?initTime=${initDate}&endTime=${endDate}`,
    {
      agents: agentsToFilter,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getTags = (token) => {
  return http.get(`/api/v1/tags/get`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteTag = (token, tagId) => {
  return http.delete(`/api/v1/tags/delete?tagId=${tagId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getAgents = (token) => {
  return http.get(`/api/v1/tags/agents/get`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateTag = (token, tagId, agents) => {
  return http.put(
    `/api/v1/tags/update?tagId=${tagId}`,
    {
      agents: agents,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const newTag = (token, name, agents) => {
  return http.post(
    `/api/v1/tags/create`,
    {
      name: name,
      agents: agents,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getUrls = (token, tag) => {
  return http.get(`/api/v1/publicUrl/get?tag=${tag}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteUrl = (token, urlId) => {
  return http.delete(`/api/v1/publicUrl/delete?urlId=${urlId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const callRecording = (token, id) => {
  return http.get(`/api/v1/stats/callRecording?id=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const StatsService = {
  getCallsByQueue,
  getCallsByQueueHourly,
  getAvgsByQueue,
  getAvgsByTrame,
  getCallsByAgent,
  getAttendedByAgent,
  getCallsByAgentOut,
  getCallsByAgentOutDetailed,
  getCallsByAgentHourlyOut,
  getAttendedByAgentHourlyOut,
  getEffectiveCalls,
  getTimingsByAgentOut,
  getTags,
  deleteTag,
  getAgents,
  updateTag,
  newTag,
  getUrls,
  deleteUrl,
  callRecording,
};

export default StatsService;
