import http from "../http-common";

const agent_sessions = ({ token, initDate, endDate }) => {
  return http.get(
    `/api/v1/bauhaus/agent_sessions?initTime=${initDate}&endTime=${endDate}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const calls_by_events = ({ token, initDate, endDate }) => {
  return http.get(
    `/api/v1/bauhaus/calls_by_events?initTime=${initDate}&endTime=${endDate}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const BauhausService = {
  agent_sessions,
  calls_by_events,
};

export default BauhausService;
