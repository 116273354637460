import http from "../http-common";

const login = (username, password) => {
  return http.post(`/api/v1/auth/signin`, {
    email: username,
    password: password,
  });
};

const create_public_token = (token, data) => {
  return http.post(`/api/v1/auth/create_public_token`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const logout = () => {
  sessionStorage.removeItem("krakenC");
  sessionStorage.removeItem("krakenT");
};
const LoginService = {
  login,
  logout,
  create_public_token,
};

export default LoginService;
