import "@elastic/eui/dist/eui_theme_light.css";

import { EuiProvider } from "@elastic/eui";
import React from "react";
import LoginComponent from "../components/Login";

const Login = () => {
  return (
    <EuiProvider colorMode="light">
      <LoginComponent />
    </EuiProvider>
  );
};

export default Login;
