import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../assets/styles/not-found.css";
import "@elastic/eui/dist/eui_theme_light.css";

import { EuiProvider, EuiCallOut } from "@elastic/eui";
import DashboardPUBLIC_OUT from "../components/DashboardPUBLIC_OUT";

const PublicURL = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState();

  useEffect(() => {
    const params_query = Object.fromEntries([...searchParams]);
    if (params_query.token) {
      setToken(params_query.token);
    } else {
      setTimeout(function () {
        navigate("/login");
      }, 5000);
    }
  }, []);

  console.log("Redirigido a PUBLIC URL");

  return (
    <>
      {token ? (
        <DashboardPUBLIC_OUT token={token} />
      ) : (
        <EuiProvider colorMode={"light"}>
          <EuiCallOut
            title="Invalid URL, redirecting to login page in 5 seconds..."
            color="warning"
            iconType="help"
          ></EuiCallOut>
        </EuiProvider>
      )}
    </>
  );
};

export default PublicURL;
