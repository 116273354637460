import http from "../http-common";

const realtime = (token, filteredqueues) => {
  return http.post(
    `/api/v1/realtime?`,
    { filteredqueues },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const RealtimeSerivce = {
  realtime,
};

export default RealtimeSerivce;
