import "@elastic/eui/dist/eui_theme_light.css";

import { EuiProvider } from "@elastic/eui";
import Header from "../components/Header";
import React, { useState } from "react";
import HomeComponent from "../components/Home";

const Home = () => {
  const [theme, setTheme] = useState("light");

  const changeTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      return;
    }
    if (theme === "dark") {
      setTheme("light");
      return;
    }
  };
  return (
    <EuiProvider colorMode={theme}>
      <div>
        <div>
          <Header changeTheme={changeTheme} />
        </div>
        <div
          style={{
            marginTop: 10,
            marginLeft: 4,
          }}
        >
          <HomeComponent />
        </div>
      </div>
    </EuiProvider>
  );
};

export default Home;
