import React, { useState } from "react";
import {
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiButton,
  EuiText,
  EuiFieldPassword,
} from "@elastic/eui";
import "../assets/styles/login.css";
import LoginService from "../services/login-service";
import { useNavigate } from "react-router-dom";

const LoginComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [isUsernameInvalid, setIsUsernameInvalid] = useState(false);
  const [usernameInvalidText, setUserameInvalidText] = useState();

  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);

  const [invalidLogin, setInvalidLogin] = useState(false);
  const [invalidLoginText, setInvalidLoginText] = useState();

  const [username, setUserame] = useState();
  const [password, setPassword] = useState();

  let navigate = useNavigate();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const userNameChanged = (e) => {
    setIsUsernameInvalid(false);
    setInvalidLogin(false);
    setUserame(e.target.value);
  };
  const passwordChanged = (e) => {
    setIsUsernameInvalid(false);

    setPassword(e.target.value);
  };

  const loginSubmitted = () => {
    if (!username) {
      setIsUsernameInvalid(true);
      setUserameInvalidText("Username is required");
      return;
    }
    if (!password) {
      setIsPasswordInvalid(true);
      return;
    }

    const isValid = validateEmail(username);

    if (!isValid) {
      setIsUsernameInvalid(true);
      setUserameInvalidText("Invalid format: jhon.doe@astroline.com");
      return;
    }
    setIsLoading(true);
    setIsDisabled(true);

    LoginService.login(username, password)
      .then((response) => {
        if (response.status !== 200) {
          setInvalidLogin(true);
          setInvalidLoginText("Wrong username/password");
          return;
        } else if (
          response.status === 200 &&
          response.data.status === "Success"
        ) {
          setIsLoading(false);
          setIsDisabled(false);
          sessionStorage.setItem("krakenC", "dem");
          sessionStorage.setItem("krakenT", response.data.accessToken);
          localStorage.setItem("CL", response.data.companyLogo);
          localStorage.setItem("UN", response.data.name);
          localStorage.setItem("CN", response.data.companyName);
          navigate(`/`);
        } else {
          setInvalidLogin(true);
          setInvalidLoginText("Wrong username/password");
        }
      })
      .catch((error) => {
        setInvalidLogin(true);
        setInvalidLoginText("Wrong username/password");
        setIsLoading(false);
        setIsDisabled(false);
      });
  };
  return (
    <div className="body">
      <div
        style={{
          position: "absolute",
          marginLeft: "5%",
          height: 200,
          marginTop: "30%",
        }}
      >
        <EuiText
          style={{
            fontSize: "50px",
            fontFamily: "Segoe UI",
          }}
          color={"white"}
        >
          {" "}
          <b>Welcome to Astroline</b>
        </EuiText>
      </div>
      <div
        style={{
          position: "absolute",
          marginLeft: "65%",
          height: 200,
          marginTop: "20%",
        }}
      >
        <EuiForm component="form">
          <EuiFormRow label="Username">
            <EuiFieldText
              style={{ width: 500 }}
              name="first"
              isInvalid={isUsernameInvalid}
              onChange={(e) => userNameChanged(e)}
            />
          </EuiFormRow>
          {isUsernameInvalid && (
            <EuiText size="xs" color={"danger"}>
              {usernameInvalidText}
            </EuiText>
          )}
          <EuiFormRow label="Password">
            <EuiFieldPassword
              type="dual"
              isInvalid={isPasswordInvalid}
              onChange={(e) => passwordChanged(e)}
              aria-label="Use aria labels when no actual label is in use"
            />
          </EuiFormRow>
          {isPasswordInvalid && (
            <EuiText size="xs" color={"danger"}>
              Password is required
            </EuiText>
          )}
          <EuiFormRow hasEmptyLabelSpace>
            <EuiButton
              isLoading={isLoading}
              isDisabled={isDisabled}
              onClick={loginSubmitted}
            >
              Login
            </EuiButton>
          </EuiFormRow>
          {invalidLogin && (
            <EuiText size="s" color={"danger"}>
              {invalidLoginText}
            </EuiText>
          )}
        </EuiForm>
      </div>
    </div>
  );
};

export default LoginComponent;
