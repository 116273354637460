import {
  EuiPanel,
  EuiStat,
  EuiFlexItem,
  EuiIcon,
  EuiSpacer,
  EuiFlexGrid,
  EuiTitle,
  EuiLoadingSpinner,
  EuiLoadingChart,
  EuiHorizontalRule,
  EuiEmptyPrompt,
} from "@elastic/eui";
import { Chart, BarSeries, Axis, Settings } from "@elastic/charts";
import { ScaleType } from "@elastic/charts";
import "@elastic/charts/dist/theme_light.css";
import React, { useState, Fragment, useEffect } from "react";
import StatsService from "../services/stats-service";
import { MdOutlinePhoneCallback } from "react-icons/md";
import { HiArrowUpRight } from "react-icons/hi2";
import { MdPhoneInTalk } from "react-icons/md";
import { BiTimer } from "react-icons/bi";
import moment from "moment";
import jwt_decode from "jwt-decode";

const DashboardPUBLIC_OUT = (props) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [tags, setTags] = useState();
  const [tagsPlain, setTagsPlain] = useState();

  const [tittle, setTittle] = useState();

  const [totalCalls, setTotalCalls] = useState();
  const [totalCallsLoading, setTotalCallsLoading] = useState(true);

  const [asr, setAsr] = useState();
  const [asrLoading, setAsrLoading] = useState(true);

  const [aht, setAht] = useState();
  const [ahtLoading, setAhtLoading] = useState(true);

  const [art, setArt] = useState();
  const [artLoading, setArtLoading] = useState(true);

  const [callsByAgentDetailed, setCallsByAgentDetailed] = useState(false);
  const [callsByAgentDetailedLoading, setCallsByAgentDetailedLoading] =
    useState(true);

  const [callsByAgentHourly, setCallsByAgentHourly] = useState(false);
  const [callsByAgentHourlyLoading, setCallsByAgentHourlyLoading] =
    useState(true);

  const [attendedByAgentHourly, setAttendedByAgentHourly] = useState(false);
  const [attendedByAgentHourlyLoading, setAttendedByAgentHourlyLoading] =
    useState(true);

  const [effectiveCalls, setEffectiveCalls] = useState(false);
  const [effectiveCallsLoading, setEffectiveCallsLoading] = useState(true);

  const [timingsByAgent, setTimingsByAgent] = useState(false);
  const [timingsByAgentLoading, setTimingsByAgentLoading] = useState(true);

  const [avgsByQueue, setAvgsByQueue] = useState(false);
  const [avgsByQueueLoading, setAvgsByQueueLoading] = useState(true);

  const [avgsByTrame, setAvgsByTrame] = useState(false);
  const [avgsByTrameLoading, setAvgsByTrameLoading] = useState(true);

  const [callsByAgent, setCallsByAgent] = useState(false);
  const [callsByAgentLoading, setCallsByAgentLoading] = useState(true);

  const [attendedByAgent, setAttendedByAgent] = useState(false);
  const [attendedByAgentLoading, setAttendedByAgentLoading] = useState(true);

  const [filterApplied, setFilterApplied] = useState(false);
  const [accessPannelErrored, setAccessPanelErrored] = useState(false);

  const filterClicked = () => {};

  useEffect(() => {
    setFilterApplied(true);
    setEffectiveCallsLoading(true);
    setTimingsByAgentLoading(true);
    setCallsByAgentHourlyLoading(true);
    setAvgsByQueueLoading(true);
    setAvgsByTrameLoading(true);
    setCallsByAgentLoading(true);
    setAttendedByAgentLoading(true);
    setTotalCallsLoading(true);
    setCallsByAgentDetailedLoading(true);
    setAttendedByAgentHourlyLoading(true);
    setCallsByAgentHourlyLoading(true);
    setAsrLoading(true);
    setAhtLoading(true);
    setArtLoading(true);
    const decoded_token = jwt_decode(props.token);
    console.log(decoded_token);

    let initDate;
    let endedDate;
    setTittle(decoded_token.tag + " - " + decoded_token.dateRange);

    if (decoded_token.dateRange === "Last 24h") {
      initDate = moment().add(-24, "h").toISOString();
      endedDate = moment().toISOString();
    }

    if (decoded_token.dateRange === "Current Week") {
      initDate = moment().startOf("isoWeek").toISOString();
      endedDate = moment().toISOString();
    }

    if (decoded_token.dateRange === "Current Month") {
      initDate = moment().startOf("month").toISOString();
      endedDate = moment().toISOString();
    }

    const agentsToFilter = [];

    for (let agent of decoded_token.agents) {
      agentsToFilter.push(agent);
    }

    const token = props.token;
    console.log(initDate);
    console.log(endedDate);
    // Abajo izquierda - Calls by agent
    StatsService.getCallsByAgentOut(token, initDate, endedDate, agentsToFilter)
      .then((response) => {
        setTotalCalls(response.data.message.totalCalls);

        const buckets = response.data.message.agentsData;
        const callsToPush = [];
        for (let bucket of buckets) {
          const key = bucket.name;
          const quantity = bucket.agentData.quantity.toString();
          callsToPush.push({ x: key, y: quantity });
        }
        setCallsByAgent(callsToPush);
        setCallsByAgentLoading(false);
        setTotalCallsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    // Arriba derecha - Calls by agent detailed
    StatsService.getCallsByAgentOutDetailed(
      token,
      initDate,
      endedDate,
      agentsToFilter
    )
      .then((response) => {
        const buckets = response.data.message.agentData;
        const callsToPush = [];
        const asrResult = parseInt(
          (100 * response.data.message.attended) /
            response.data.message.totalCalls
        );
        setAsr(asrResult);
        for (let bucket of buckets) {
          const key = bucket.name;
          for (let code of bucket.statusData) {
            callsToPush.push({ x: key, y: code.quantity, g: code.name });
          }
        }
        setCallsByAgentDetailed(callsToPush);
        setCallsByAgentDetailedLoading(false);
        setAsrLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    // Enmedio izquierda, calls by agent by hours
    StatsService.getCallsByAgentHourlyOut(
      token,
      initDate,
      endedDate,
      agentsToFilter
    )
      .then((response) => {
        const buckets = response.data.message.trames;
        const callsToPush = [];
        for (let bucket of buckets) {
          const key = bucket.name;
          if (bucket.agentData.length < 1) {
            callsToPush.push({ x: key, y: 0, g: "NONE" });
          }
          for (let code of bucket.agentData) {
            callsToPush.push({ x: key, y: code.quantity, g: code.name });
          }
        }
        setCallsByAgentHourly(callsToPush);
        setCallsByAgentHourlyLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    // Enmedio derecha, attended by agent by hours
    StatsService.getAttendedByAgentHourlyOut(
      token,
      initDate,
      endedDate,
      agentsToFilter
    )
      .then((response) => {
        const buckets = response.data.message.trames;
        const callsToPush = [];
        for (let bucket of buckets) {
          const key = bucket.name;
          if (bucket.agentData.length < 1) {
            callsToPush.push({ x: key, y: 0, g: "NONE" });
          }
          for (let code of bucket.agentData) {
            callsToPush.push({ x: key, y: code.quantity, g: code.name });
          }
        }
        setAttendedByAgentHourly(callsToPush);
        setAttendedByAgentHourlyLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    //Abajo izquierda
    StatsService.getEffectiveCalls(token, initDate, endedDate, agentsToFilter)
      .then((response) => {
        const buckets = response.data.message.agentsData;
        const callsToPush = [];

        for (let bucket of buckets) {
          const key = bucket.name;
          const quantity = bucket.agentData.quantity.toString();
          callsToPush.push({ x: key, y: quantity });
        }
        setEffectiveCalls(callsToPush);
        setEffectiveCallsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    //Abajo derecha timings by agent

    StatsService.getTimingsByAgentOut(
      token,
      initDate,
      endedDate,
      agentsToFilter
    )
      .then((response) => {
        const buckets = response.data.message.agents;
        const callsToPush = [];
        for (let bucket of buckets) {
          const key = bucket.name;
          for (let code of bucket.stats) {
            callsToPush.push({ x: key, y: code.value, g: code.name });
          }
        }

        setAht(response.data.message.avgDurationTime);
        setAhtLoading(false);

        setArt(response.data.message.avgRingingTime);
        setArtLoading(false);

        setTimingsByAgent(callsToPush);
        setTimingsByAgentLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div
      style={{
        width: "97%",
        marginLeft: "1.5%",
        marginRight: "1.5%",
        marginTop: "1.5%",
      }}
    >
      {accessPannelErrored ? (
        <EuiEmptyPrompt
          iconType="alert"
          color="danger"
          title={<h2>Unable to load your dashboards</h2>}
          body={
            <p>
              There was an error loading the Dashboard application. Contact your
              administrator for help support@astroline.com.
            </p>
          }
        />
      ) : (
        <>
          <Fragment>
            <div>
              <EuiFlexGrid columns={4} responsive={false}>
                <EuiFlexItem style={{ height: 10 }}>
                  <EuiPanel>
                    <>
                      <EuiStat
                        title={
                          totalCallsLoading ? (
                            <div>
                              <EuiLoadingSpinner size="xl" />
                            </div>
                          ) : (
                            <div>
                              <span>
                                <MdOutlinePhoneCallback
                                  style={{ marginBottom: -5 }}
                                />
                              </span>
                              <span> {totalCalls}</span>
                            </div>
                          )
                        }
                        description="Total calls"
                        titleColor="#7334A2"
                        textAlign="right"
                      >
                        <EuiSpacer size="s" />
                      </EuiStat>
                    </>
                  </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiPanel>
                    <EuiStat
                      title={
                        asrLoading ? (
                          <div>
                            <EuiLoadingSpinner size="xl" />
                          </div>
                        ) : (
                          <div>
                            <span>
                              <HiArrowUpRight style={{ marginBottom: -3 }} />
                            </span>
                            <span> {asr}%</span>
                          </div>
                        )
                      }
                      description="Answer Seizure Ratio"
                      titleColor="#81D4AE"
                      textAlign="right"
                    >
                      <EuiSpacer size="s" />
                    </EuiStat>
                  </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiPanel>
                    <EuiStat
                      title={
                        ahtLoading ? (
                          <div>
                            <EuiLoadingSpinner size="xl" />
                          </div>
                        ) : (
                          <div>
                            <div>
                              <span>
                                <BiTimer style={{ marginBottom: -3 }} />
                              </span>
                              <span> {aht}</span>
                            </div>
                          </div>
                        )
                      }
                      description="Average Handle Time"
                      titleColor="#7334A2"
                      textAlign="right"
                    >
                      <EuiSpacer size="s" />
                    </EuiStat>
                  </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiPanel>
                    <EuiStat
                      title={
                        artLoading ? (
                          <div>
                            <EuiLoadingSpinner size="xl" />
                          </div>
                        ) : (
                          <div>
                            <div>
                              <span>
                                <MdPhoneInTalk style={{ marginBottom: -3 }} />
                              </span>
                              <span> {art}</span>
                            </div>
                          </div>
                        )
                      }
                      description="Average Ringing Time"
                      titleColor="#81D4AE"
                      textAlign="right"
                    >
                      <EuiSpacer size="s" />
                    </EuiStat>
                  </EuiPanel>
                </EuiFlexItem>
              </EuiFlexGrid>
            </div>
            <div style={{ marginTop: 50 }}>
              <div style={{ display: "flex" }}>
                <div>
                  <EuiTitle size="l">
                    <h1>
                      {" "}
                      <EuiIcon type="graphApp" size="xl" />{" "}
                      {filterApplied ? <>{tittle}</> : <>Day's operation</>}
                    </h1>
                  </EuiTitle>
                </div>
              </div>
              <EuiSpacer />
              <EuiHorizontalRule
                margin="none"
                style={{ height: 2, marginRight: 10 }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <EuiFlexGrid columns={2} responsive={true}>
                <EuiFlexItem>
                  <div
                    style={{ widht: "100%", height: "18vh", marginTop: "2vh" }}
                  >
                    <EuiIcon type="usersRolesApp" size="l" />
                    &nbsp;&nbsp;
                    <b>Calls By Agent</b>
                    {callsByAgentLoading ? (
                      <div style={{ marginTop: "6vh", marginLeft: "50%" }}>
                        <EuiLoadingChart size="m" mono />
                      </div>
                    ) : (
                      <Chart>
                        <Settings legendPosition={"right"} rotation={90} />
                        <BarSeries
                          id="bars2"
                          name="Amount"
                          xScaleType="ordinal"
                          xAccessor="x"
                          yAccessors={["y"]}
                          data={callsByAgent}
                        />

                        <Axis
                          title={"Agent"}
                          id="left-axis"
                          position="left"
                          showGridLines
                        />
                      </Chart>
                    )}
                  </div>
                </EuiFlexItem>
                <EuiFlexItem>
                  <div
                    style={{ widht: "100%", height: "18vh", marginTop: "2vh" }}
                  >
                    <EuiIcon type="usersRolesApp" size="l" />
                    &nbsp;&nbsp;
                    <b>Calls by Agent Detailed</b>
                    {callsByAgentDetailedLoading ? (
                      <div style={{ marginTop: "6vh", marginLeft: "50%" }}>
                        <EuiLoadingChart size="m" mono />
                      </div>
                    ) : (
                      <Chart>
                        <Settings
                          showLegend
                          showLegendExtra
                          legendPosition={"right"}
                          rotation={90}
                        />
                        <BarSeries
                          id="bars2"
                          xScaleType="ordinal"
                          xAccessor="x"
                          yAccessors={["y"]}
                          stackAccessors={["x"]}
                          splitSeriesAccessors={["g"]}
                          data={callsByAgentDetailed}
                        />

                        <Axis
                          title={"Agent"}
                          id="left-axis"
                          position="left"
                          showGridLines
                        />
                      </Chart>
                    )}
                  </div>
                </EuiFlexItem>
                <EuiFlexItem>
                  <div
                    style={{ widht: "100%", height: "18vh", marginTop: "2vh" }}
                  >
                    <EuiIcon type="machineLearningApp" size="l" />
                    &nbsp;&nbsp;
                    <b>Call attempts hourly</b>
                    {callsByAgentHourlyLoading ? (
                      <div style={{ marginTop: "6vh", marginLeft: "50%" }}>
                        <EuiLoadingChart size="m" mono />
                      </div>
                    ) : (
                      <Chart>
                        <Settings
                          showLegend
                          showLegendExtra
                          legendPosition={"right"}
                        />
                        <BarSeries
                          id="bars2"
                          xScaleType="ordinal"
                          xAccessor="x"
                          yAccessors={["y"]}
                          stackAccessors={["x"]}
                          splitSeriesAccessors={["g"]}
                          data={callsByAgentHourly}
                        />
                        <Axis
                          title={"Agent"}
                          id="bottom-axis"
                          position="bottom"
                        />
                        <Axis
                          title={"Calls"}
                          id="left-axis"
                          position="left"
                          showGridLines
                        />
                      </Chart>
                    )}
                  </div>
                </EuiFlexItem>
                <EuiFlexItem>
                  <div
                    style={{ widht: "100%", height: "18vh", marginTop: "2vh" }}
                  >
                    <EuiIcon type="machineLearningApp" size="l" />
                    &nbsp;&nbsp;
                    <b>Attended calls hourly</b>
                    {attendedByAgentHourlyLoading ? (
                      <div style={{ marginTop: "6vh", marginLeft: "50%" }}>
                        <EuiLoadingChart size="m" mono />
                      </div>
                    ) : (
                      <Chart>
                        <Settings
                          showLegend
                          showLegendExtra
                          legendPosition={"right"}
                        />
                        <BarSeries
                          id="bars2"
                          xScaleType="ordinal"
                          xAccessor="x"
                          yAccessors={["y"]}
                          stackAccessors={["x"]}
                          splitSeriesAccessors={["g"]}
                          data={attendedByAgentHourly}
                        />
                        <Axis
                          title={"Agent"}
                          id="bottom-axis"
                          position="bottom"
                        />
                        <Axis
                          title={"Calls"}
                          id="left-axis"
                          position="left"
                          showGridLines
                        />
                      </Chart>
                    )}
                  </div>
                </EuiFlexItem>
                <EuiFlexItem>
                  <div
                    style={{ widht: "100%", height: "18vh", marginTop: "2vh" }}
                  >
                    <EuiIcon type="recentlyViewedApp" size="l" />
                    &nbsp;&nbsp;
                    <b>Effective Calls &gt;3min</b>
                    {effectiveCallsLoading ? (
                      <div style={{ marginTop: "6vh", marginLeft: "50%" }}>
                        <EuiLoadingChart size="m" mono />
                      </div>
                    ) : (
                      <Chart>
                        <Settings legendPosition={"right"} rotation={90} />
                        <BarSeries
                          id="bars2"
                          name="Amount"
                          xScaleType="ordinal"
                          xAccessor="x"
                          yAccessors={["y"]}
                          data={effectiveCalls}
                        />

                        <Axis
                          title={"Agent"}
                          id="left-axis"
                          position="left"
                          showGridLines
                        />
                      </Chart>
                    )}
                  </div>
                </EuiFlexItem>
                <EuiFlexItem>
                  <div
                    style={{ widht: "100%", height: "18vh", marginTop: "2vh" }}
                  >
                    <EuiIcon type="recentlyViewedApp" size="l" />
                    &nbsp;&nbsp;
                    <b>Timings by Agent</b>
                    {timingsByAgentLoading ? (
                      <div style={{ marginTop: "6vh", marginLeft: "50%" }}>
                        <EuiLoadingChart size="m" mono />
                      </div>
                    ) : (
                      <Chart>
                        <Settings
                          showLegend
                          showLegendExtra
                          legendPosition={"right"}
                          rotation={90}
                        />
                        <BarSeries
                          id="bars2"
                          displayValueSettings={true}
                          xScaleType={ScaleType.Linear}
                          yScaleType={ScaleType.Linear}
                          xAccessor="x"
                          yAccessors={["y"]}
                          stackAccessors={["x"]}
                          splitSeriesAccessors={["g"]}
                          data={timingsByAgent}
                        />
                        <Axis
                          title={"Agents"}
                          id="left-axis"
                          position="left"
                          showGridLines
                        />
                      </Chart>
                    )}
                  </div>
                </EuiFlexItem>
              </EuiFlexGrid>
            </div>
          </Fragment>
        </>
      )}
    </div>
  );
};

export default DashboardPUBLIC_OUT;
